import React, { FC, useCallback, useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import UploadFile from '../../Components/UploadFile/UploadFile';
import { deleteFile, getFile, postFile } from '../../Services/http/content.service';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import { useSnackbar } from 'notistack';
import { FileTypes } from '../../Enums/FileTypes.enum';
import { InputFileType } from '../../Enums/InputFileType.enums';
import { BannerTypes } from '../../Enums/BannerTypes.enum';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';
export interface Banner {
    title: string;
    type: BannerTypes;
}

const useStyles = makeStyles(() => ({
    bannersList: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
    },
    bannerRow: { width: '100%', marginBottom: '10px' },
    loaderWrapper: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transaction: 'translateX(-50%), translateY(-50%)',
    },
}));

const PicturesAndBannerAccordionContainer: FC = () => {
    const banners = [
        {
            title: 'Login banner: min. 800x800 px / recommended 1200x1200 px',
            type: BannerTypes.LOGIN,
        },
        {
            title: 'Light version banner: min. 375x200 px / recommended 750x400 px',
            type: BannerTypes.PROFILE,
        },
        { title: 'Email banner: 1280x250 px', type: BannerTypes.EMAIL },
    ];
    const { state, dispatch } = useEventManagement();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [loginBannerFile, setLoginBannerFile] = useState<File | null>(null);
    const [profileBannerFile, setProfileBannerFile] = useState<File | null>(null);
    const [emailBannerFile, setEmailBannerFile] = useState<File | null>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    // Login banner
    const [posterImage, setPosterImage] = useState('');
    const [posterName, setPosterName] = useState('login.png');
    const [revertPoster, setRevertPoster] = useState(false);

    // Profile banner
    const [profileImage, setProfileImage] = useState('');
    const [profileName, setProfileName] = useState('profile.png');
    const [revertProfile, setRevertProfile] = useState(false);

    // Email banner
    const [emailImage, setEmailImage] = useState('');
    const [emailName, setEmailName] = useState('email.png');
    const [revertEmail, setRevertEmail] = useState(false);

    const getImageFile = useCallback(
        async (fileType) => {
            let setFunction: Function = () => {};
            let filename = '';
            switch (fileType) {
                case FileTypes.POSTER: {
                    setFunction = setPosterImage;
                    break;
                }
                case FileTypes.BANNER: {
                    setFunction = setProfileImage;
                    break;
                }
                case FileTypes.EMAIL: {
                    filename = 'banner.png';
                    setFunction = setEmailImage;
                    break;
                }
                default: {
                    break;
                }
            }
            getFile(fileType, filename, state.eventCode)
                .then((res) => {
                    const fileIsDefault =
                        res.headers &&
                        res.headers['content-disposition'] &&
                        res.headers['content-disposition'].includes('filename=default-');
                    if (!fileIsDefault) {
                        setFunction(URL.createObjectURL(res.data));
                    }
                })
                .catch((e) => {
                    // Throws error if default file is missing
                    console.error('An error occurred:', e.message);
                });
        },
        [state.eventCode],
    );

    useEffect(() => {
        getImageFile(FileTypes.POSTER);
        getImageFile(FileTypes.BANNER);
        getImageFile(FileTypes.EMAIL);
    }, [getImageFile]);

    const postFileFunc = (file: File, fileType: FileTypes) => {
        setIsLoading(true);
        if (fileType === FileTypes.EMAIL) {
            // POSTER and BANNER have default filenames on the BE, so the filename is unnecessary, email does not currently
            // Future changes will replace this with an email asset type which will correspond with a default filename on the BE
            file = new File([file], 'banner.png');
        }
        state.eventCode &&
            postFile(file, fileType, state.eventCode)
                .then(() => {
                    enqueueSnackbar('The new files have been uploaded successfully.', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    resetSelection();
                    getImageFile(fileType);
                    setIsLoading(false);
                    setIsExpanded(true);
                })
                .catch((e) => {
                    enqueueSnackbar(e.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    setIsLoading(false);
                    setIsExpanded(true);
                });
    };

    function deleteFileFunc(fileType: FileTypes) {
        setIsLoading(true);
        let filename = '';
        if (fileType === FileTypes.EMAIL) {
            // POSTER and BANNER have default filenames on the BE, so the filename is unnecessary, email does not currently
            // Future changes will replace this with an email asset type which will correspond with a default filename on the BE
            filename = 'banner.png';
        }
        state.eventCode &&
            deleteFile(filename, fileType, state.eventCode)
                .then(() => {
                    enqueueSnackbar('Successfully reverted to default.', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    resetSelection();
                    resetImageLink(fileType);
                    setIsLoading(false);
                    setIsExpanded(true);
                })
                .catch((e) => {
                    enqueueSnackbar(e.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    setIsLoading(false);
                    setIsExpanded(true);
                });
    }

    const onClick = () => {
        if (isExpanded) {
            setIsExpanded(false);
        } else {
            setIsExpanded(true);
        }
    };

    // Handles file upload event and updates state
    const handleSelectFile = (file: File, type: BannerTypes) => {
        switch (type) {
            case BannerTypes.LOGIN: {
                setLoginBannerFile(file);
                setDisabled(false);
                break;
            }
            case BannerTypes.PROFILE: {
                setProfileBannerFile(file);
                setDisabled(false);
                break;
            }
            case BannerTypes.EMAIL: {
                setEmailBannerFile(file);
                setDisabled(false);
                break;
            }
            default: {
                break;
            }
        }
    };

    const handleRevertToDefault = (revert: boolean, type: BannerTypes) => {
        keepAccordionOpen();
        switch (type) {
            case BannerTypes.LOGIN: {
                setRevertPoster(revert);
                setDisabled(false);
                break;
            }
            case BannerTypes.PROFILE: {
                setRevertProfile(revert);
                setDisabled(false);
                break;
            }
            case BannerTypes.EMAIL: {
                setRevertEmail(revert);
                setDisabled(false);
                break;
            }
            default: {
                break;
            }
        }
    };

    const onImageClick = () => {
        keepAccordionOpen();
    };

    function keepAccordionOpen() {
        setTimeout(() => {
            setIsExpanded(true);
        }, 0);
    }

    const onSubmit = () => {
        if (loginBannerFile) {
            postFileFunc(loginBannerFile, FileTypes.POSTER);
        } else if (revertPoster) {
            deleteFileFunc(FileTypes.POSTER);
        }

        if (profileBannerFile) {
            postFileFunc(profileBannerFile, FileTypes.BANNER);
        } else if (revertProfile) {
            deleteFileFunc(FileTypes.BANNER);
        }

        if (emailBannerFile) {
            postFileFunc(emailBannerFile, FileTypes.EMAIL);
        } else if (revertEmail) {
            deleteFileFunc(FileTypes.EMAIL);
        }
    };

    function resetImageLink(type: FileTypes) {
        switch (type) {
            case FileTypes.POSTER: {
                setPosterImage('');
                break;
            }
            case FileTypes.BANNER: {
                setProfileImage('');
                break;
            }
            case FileTypes.EMAIL: {
                setEmailImage('');
                break;
            }
            default: {
                break;
            }
        }
    }

    const onCancel = () => {
        resetSelection();
        setIsExpanded(false);
        setDisabled(true);
    };

    const resetSelection = () => {
        setLoginBannerFile(null);
        setEmailBannerFile(null);
        setProfileBannerFile(null);
        setRevertPoster(false);
        setRevertProfile(false);
        setRevertEmail(false);
    };

    const renderUploadFile = (banner: Banner) => {
        let selectedFile = null;
        let setSelectedFile: any;
        let currentFile = null;
        let currentFilename = '';
        let revertToDef = false;

        switch (banner.type) {
            case BannerTypes.LOGIN: {
                selectedFile = loginBannerFile;
                setSelectedFile = setLoginBannerFile;
                currentFile = posterImage;
                currentFilename = posterName;
                revertToDef = revertPoster;
                break;
            }
            case BannerTypes.PROFILE: {
                selectedFile = profileBannerFile;
                setSelectedFile = setProfileBannerFile;
                currentFile = profileImage;
                currentFilename = profileName;
                revertToDef = revertProfile;
                break;
            }
            case BannerTypes.EMAIL: {
                selectedFile = emailBannerFile;
                setSelectedFile = setEmailBannerFile;
                currentFile = emailImage;
                currentFilename = emailName;
                revertToDef = revertEmail;
                break;
            }
            default:
                break;
        }
        return (
            <li key={`room-name-${banner.type}`} className={classes.bannerRow}>
                <UploadFile
                    banner={banner}
                    onSelect={(file) => handleSelectFile(file, banner.type)}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    inputFileType={InputFileType.PNG}
                    includeDefault={true}
                    currentFile={currentFile}
                    currentFilename={currentFilename}
                    revertToDefault={revertToDef}
                    setRevertToDefault={(val) => handleRevertToDefault(val, banner.type)}
                    onImageClick={onImageClick}
                />
            </li>
        );
    };

    return (
        <Accordion expanded={isExpanded} onClick={onClick}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    <strong>{EventAdministrationItems.PICTURES_AND_BANNER}</strong>
                </Typography>
            </AccordionSummary>
            <List className={classes.bannersList}>
                {banners.map((banner) => renderUploadFile(banner))}
            </List>
            <CancelAndConfirmButton onCancel={onCancel} onSubmit={onSubmit} disabled={disabled} />
            {isLoading && (
                <div className={classes.loaderWrapper}>
                    <CircularProgress />
                </div>
            )}
        </Accordion>
    );
};

export default PicturesAndBannerAccordionContainer;
