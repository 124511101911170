import { Button, ButtonGroup, makeStyles } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RoomChatManagementTable } from '../../Components/RoomChatManagementTable/RoomChatManagementTable';
import AlertDialog from '../../Components/Dialogs/AlertDialog/AlertDialog';
import {
    deleteAllMessages,
    deleteMessage,
    getConversationsInRoom,
    getConversationsInRoomFromID,
} from '../../Services/http/conversations.service';
import { GridRowModel } from '@material-ui/data-grid';
import TextInputDialog from '../../Components/Dialogs/TextInputDialog/TextInputDialog';
import { connectMessagingSocket, emitMessagingSocket } from '../../Services/http/socket.service';
import { Conversation, Room } from '../../Models/socket.model';
import useSocket from '../../Hooks/Socket/socket';
import useAuth from '../../Hooks/Auth/useAuth';
import { UPDATE_SOCKET_CONNECTION } from '../../Contexts/socketContext';
import { logout, refreshJwt } from '../../Services/http/auth.service';
import { setLocalStorageItemValue } from '../../Services/local-storage.service';
import useRoomManagement from '../../Hooks/RoomManagement/useRoomManagement';
import {
    UPDATE_SELECTED_MESSAGE,
    UPDATE_SELECTED_ROOM,
} from '../../Contexts/roomManagementContext';
import { useSnackbar } from 'notistack';
import { refreshJwtfunc } from '../../Utils/axiosInterceptors';

const useStyles = makeStyles(() => ({
    buttonRow: {
        margin: '40px 0',
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    roomConversationContainer: {
        marginTop: '50px',
        textAlign: 'center',
        '& span': { fontSize: '0.8em' },
    },
    conversationTable: { display: 'flex', flexDirection: 'column' },
}));

interface Props {
    eventCode: string;
}

export const RoomChatManagementTableContainer: FC<Props> = (props) => {
    const { eventCode } = props;
    const { state: roomManagementState, dispatch: roomManagementDispatch } = useRoomManagement();
    const { state: authState } = useAuth();
    const { state: socketState, dispatch: socketDispatch } = useSocket();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [roomMessages, setRoomMessages] = useState([] as Conversation[]);
    const [conversationId, setConversationId] = useState('');

    const [room, setRoom] = useState<Room>();
    const [hasEmptyMessage, setHasEmptyMessage] = useState(true);
    const [socketTopicListener, setSocketTopicListener] = useState(null as any);

    const [selectedMessagesIds, setSelectedMessagesIds] = useState<string[]>([]);
    const [deleteAllMessagesDialogOpen, setDeleteAllMessagesDialogOpen] = useState(false);
    const [sendNewMessageDialogOpen, setSendNewMessageDialogOpen] = useState(false);
    const [conversationTableRows, setConversationTableRows] = useState<GridRowModel[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [socketTopic] = useState(`chat_${eventCode}`);

    const { currentUser } = authState;
    const { selectedRoom } = roomManagementState;
    const { socketConnection } = socketState;
    const { id } = currentUser;
    const { dispatch } = useRoomManagement();

    const columns = [
        { field: 'message', headerName: 'Message', flex: 1 },
        { field: 'sender', headerName: 'Sender', flex: 1 },
        {
            field: 'dateTime',
            headerName: 'Date & Time',
            flex: 1,
            options: { sortDirection: 'asc' },
        },
    ];
    const getRoomMessages = useCallback(
        (cid: string) => {
            setIsLoading(true);
            getConversationsInRoomFromID(cid)
                .then(({ data }) => {
                    setRoomMessages(data.messages);
                    setIsLoading(false);
                    if (data.messages.length === 0) {
                        setHasEmptyMessage(true);
                    } else {
                        setHasEmptyMessage(false);
                    }
                    dispatch({ type: UPDATE_SELECTED_MESSAGE, payload: roomMessages });
                })
                .catch(() => {
                    setHasEmptyMessage(true);
                    setIsLoading(false);
                });
        },
        [dispatch, roomMessages],
    );
    useEffect(() => {
        if (selectedRoom) {
            setRoom(selectedRoom);
            setIsLoading(true);
            getConversationsInRoom(selectedRoom.id)
                .then(({ data }) => {
                    setConversationId(data.conversation_id);
                    getRoomMessages(data.conversation_id);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (authState.currentUser && !socketState.socketConnection) {
            let socket = connectMessagingSocket(
                authState.currentUser,
                (selectedRoom as Room).id,
                eventCode,
            );
            socket.on('connect_error', (error) => {
                console.error('An error occurred:', error.message);
                logout().then();
                socket = connectMessagingSocket(authState.currentUser);
                getRoomMessages(conversationId);
            });
            socket.on('refresh_token', async (data, callback) => {
                if (data.initiateRefreshFlag) {
                    const refreshJwtResponseData = await refreshJwtfunc();
                    authState.currentUser.jwt_token = refreshJwtResponseData.user_data.jwt_token;
                    setLocalStorageItemValue('currentUser', JSON.stringify(authState.currentUser));
                }
                callback('refreshToken message consumed');
            });
            socket.onAny((eventName, ...args) => {
                console.log('socket on any', eventName, ...args);
            });
            socketDispatch({
                type: UPDATE_SOCKET_CONNECTION,
                payload: socket,
            });
        }

        return () => {
            if (socketState.socketConnection) {
                socketState.socketConnection.disconnect();
                socketDispatch({
                    type: UPDATE_SOCKET_CONNECTION,
                    payload: null,
                });
            }
        };
    }, [
        authState,
        socketState,
        socketDispatch,
        selectedRoom,
        roomMessages,
        eventCode,
        getRoomMessages,
        conversationId,
    ]);

    const deleteSelected = () => {
        if (selectedMessagesIds.length > 0) {
            setIsLoading(true);
            dispatch({ type: UPDATE_SELECTED_MESSAGE, payload: roomMessages });
            socketDispatch({
                type: UPDATE_SOCKET_CONNECTION,
                payload: null,
            });
            Promise.all([selectedMessagesIds.forEach((mid) => deleteMessage(mid))])
                .then(() => {
                    selectedMessagesIds.forEach((mid) => sendDeleteMessage(mid));
                    setTimeout(function () {
                        getRoomMessages(conversationId);
                    }, 500);
                    enqueueSnackbar('The selected messages are deleted', {
                        variant: 'success',
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .catch((error) => {
                    enqueueSnackbar(error?.data?.message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                });
        }
    };

    useEffect(() => {
        refreshTableRows(roomMessages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomMessages]);

    function refreshTableRows(roomMessages: Conversation[]) {
        const rows: GridRowModel[] = [];
        roomMessages?.forEach((message) => {
            rows.push({
                id: message.id,
                message: message.message,
                sender: message.from,
                dateTime: convertToDate(message.timestamp),
            });
        });

        setConversationTableRows(rows);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const convertToDate = (timeStamp: string) => {
        const realTimeStamp = Number(timeStamp);
        const date = new Date(realTimeStamp);
        return date.toLocaleString();
    };

    const setSelectedMessage = (event: any) => {
        setSelectedMessagesIds(event);
    };

    const deleteAllTextMessages = () => {
        dispatch({ type: UPDATE_SELECTED_MESSAGE, payload: roomMessages });
        socketDispatch({
            type: UPDATE_SOCKET_CONNECTION,
            payload: null,
        });
        setDeleteAllMessagesDialogOpen(false);
        if (conversationId) {
            deleteAllMessages(conversationId)
                .then((res) => {
                    conversationTableRows?.forEach((conversation: any) => {
                        sendDeleteMessage(conversation.id as string);
                    });
                    enqueueSnackbar('All messages were deleted successfully', {
                        variant: 'success',
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    getRoomMessages(conversationId);
                })
                .catch((error) => {
                    enqueueSnackbar('The messages could not be deleted', {
                        variant: 'error',
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                });
        }
    };

    const sendNewMessage = (text: string) => {
        if (currentUser && socketConnection) {
            setIsLoading(true);
            dispatch({ type: UPDATE_SELECTED_MESSAGE, payload: roomMessages });
            socketDispatch({
                type: UPDATE_SOCKET_CONNECTION,
                payload: null,
            });
            const data: Conversation = {
                from: id,
                to: [],
                room: (selectedRoom as Room).id,
                type: 'text',
                timestamp: JSON.stringify(Date.now()),
                message: text,
                id: JSON.stringify(self.crypto.getRandomValues(new Uint32Array(1))[0]),
                event_code: eventCode,
                conversation: conversationId,
            };

            emitMessagingSocket(JSON.stringify(data), socketConnection, socketTopic);
            setTimeout(function () {
                getRoomMessages(conversationId);
            }, 500);
            if (!socketTopicListener) {
                const chatListener = socketConnection.on(socketTopic, (res) => {
                    // success handling
                    setHasEmptyMessage(false);
                    setRoomMessages([...roomMessages, JSON.parse(res)]);
                });

                setSocketTopicListener(chatListener);
            }
            setSendNewMessageDialogOpen(false);
            enqueueSnackbar('New message was sent successfully', {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        }
    };

    const sendDeleteMessage = (messageId?: string) => {
        const payload: any = {
            from: id,
            to: [],
            room: (selectedRoom as Room).id,
            conversation: conversationId,
            event_code: eventCode,
            type: 'text',
            action: 'delete',
        };

        if (messageId) {
            payload['message'] = messageId;
        }
        if (socketConnection) {
            emitMessagingSocket(JSON.stringify(payload), socketConnection, socketTopic);
        }
        refreshTableRows(roomMessages);
    };

    return (
        <>
            <div className={classes.buttonRow}>
                <Button
                    variant="contained"
                    onClick={() =>
                        roomManagementDispatch({ type: UPDATE_SELECTED_ROOM, payload: null })
                    }
                    id="chat-list-page-back"
                >
                    Back to list of Reality Linking
                </Button>
                <ButtonGroup variant="contained" aria-label="contained primary button group">
                    <Button onClick={deleteSelected}>Delete</Button>
                    <Button onClick={() => setDeleteAllMessagesDialogOpen(true)}>Delete All</Button>
                    <Button onClick={() => setSendNewMessageDialogOpen(true)}>Send Message</Button>
                </ButtonGroup>
            </div>
            <div>
                {hasEmptyMessage ? (
                    <p className={classes.roomConversationContainer}>
                        {`You have no conversations in ${room?.name}.`}
                        <br />
                        <span>{`For privacy reasons, we only display conversations you've taken part in.`}</span>
                    </p>
                ) : (
                    conversationTableRows && (
                        <div className={classes.conversationTable}>
                            <h1>{room?.name}</h1>
                            <RoomChatManagementTable
                                conversationTableRows={conversationTableRows}
                                columns={columns}
                                setSelectedMessage={setSelectedMessage}
                                isLoading={isLoading}
                            />
                        </div>
                    )
                )}
                <AlertDialog
                    isDialogOpen={deleteAllMessagesDialogOpen}
                    onClose={() => setDeleteAllMessagesDialogOpen(false)}
                    onConfirm={deleteAllTextMessages}
                    confirmButtonText="Delete all Messages"
                    title="Do you want to delete all this conversation's messages?"
                />
                <TextInputDialog
                    isDialogOpen={sendNewMessageDialogOpen}
                    onClose={() => setSendNewMessageDialogOpen(false)}
                    onConfirm={(e: any) => sendNewMessage(e)}
                    confirmButtonText="Send"
                    title="Send Message"
                />
            </div>
        </>
    );
};
