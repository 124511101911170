import { IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Grid, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardForm: {
            border: '0.5px solid',
            borderRadius: '0.25rem',
            padding: theme.spacing(2),
            borderColor: '#484848',
            backgroundColor: '#1E1E1E',
        },
        cardFormHeader: {
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1),
            alignItems: 'center',
        },
        cardFormTitle: {
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1),
            alignItems: 'center',
        },
        infoIcon: {
            color: theme.palette.action.active,
        },
    }),
);

interface Props {
    title: string;
    popoverContent: string;
    children?: React.ReactNode;
    topComponent?: React.ReactNode;
}

export const CardCreation: React.FC<Props> = ({
    title,
    popoverContent,
    children,
    topComponent,
}) => {
    const classes = useStyles();

    return (
        <Grid item className={classes.cardForm} container gap={2}>
            <Grid container justifyContent="space-between" className={classes.cardFormHeader}>
                <Grid item className={classes.cardFormTitle}>
                    <Typography variant="h6" fontWeight="fontWeightBold">
                        {title}
                    </Typography>
                    <Tooltip title={popoverContent} placement="right">
                        <IconButton>
                            <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid>{topComponent && topComponent}</Grid>
            </Grid>
            {children}
        </Grid>
    );
};
