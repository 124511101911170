import React, { FC, useState } from 'react';
import ActionButton from '../../Components/ActionButton/ActionButton';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import { EDIT_ADMINISTRATION } from '../../Contexts/eventManagementContext';
import { useEffect } from 'react';

interface Props {
    onCompleteCallback: () => void;
}

const EventAdministrationContainer: FC<Props> = ({ onCompleteCallback }) => {
    const { state, dispatch } = useEventManagement();

    const [isAdministrationOpen, setIsAdministrationOpen] = useState(true);

    useEffect(() => {
        const { isAdministrationOpen: administration } = state;
        setIsAdministrationOpen(administration);
    }, [state]);

    const onEditAdministrationClick = (isAdministrationOpen: boolean) => {
        dispatch({
            type: EDIT_ADMINISTRATION,
            payload: isAdministrationOpen,
        });
    };
    return (
        <ActionButton
            icon
            onClick={() => onEditAdministrationClick(true)}
            text={'Edit Administration'}
            id="domains-edit-administration"
        />
    );
};

export default EventAdministrationContainer;
