import React, { FC, useEffect, useState } from 'react';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import ActionButton from '../../Components/ActionButton/ActionButton';
import UserActionDialog from '../../Components/Dialogs/UserActionDialog/UserActionDialog';
import { Theme, makeStyles } from '@material-ui/core';
import CompleteDialog from '../../Components/Dialogs/ActionCompleteDialog/ActionCompleteDialog';
import { deleteUser } from '../../Services/http/user.service';
import { useSnackbar } from 'notistack';
import ActionConfirmDialog from '../../Components/Dialogs/ActionConfirmDialog/ActionConfirmDialog';
import { UPDATE_SELECTED_USERS, UPDATE_USER_LIST } from '../../Contexts/userManagementContext';
import useAuth from '../../Hooks/Auth/useAuth';
import { removeAllRoles } from '../../Services/http/auth.service';

const useStyles = makeStyles((theme: Theme) => ({
    inputContiainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 70,
        },
    },
}));

interface Props {
    onCompleteCallback: () => void;
}

const DeleteUserContainer: FC<Props> = ({ onCompleteCallback }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { state: userState, dispatch } = useUserManagement();
    const { state: authState } = useAuth();
    const [isOpen, setOpen] = useState(false);
    const [isEventCodeRemoved, setIsEventCodeRemoved] = useState(false);

    const toggleOpen = () => {
        setOpen(!isOpen);
    };

    const toggleEventCodeDialog = () => {
        setIsEventCodeRemoved(!isEventCodeRemoved);
    };

    const handleCloseCompleteDialog = () => {
        if (onCompleteCallback) {
            onCompleteCallback();
        }
        toggleEventCodeDialog();
    };

    const onDelete = async (userId: any) => {
        const activeEventCode = authState.currentUser?.event_code;
        if (activeEventCode) {
            await removeAllRoles(userId, activeEventCode).then(() => {
                const newUsers = userState.users.map((_user) => {
                    if (_user.id === userId) {
                        if (_user.roles) {
                            _user.roles = [
                                ..._user.roles.filter(
                                    (role) => role.event_code !== activeEventCode,
                                ),
                            ];
                        }
                    }
                    return _user;
                });
                dispatch({ type: UPDATE_USER_LIST, payload: { users: newUsers } });
                setOpen(false);
            });
        }
    };

    const onConfirm = async () => {
        const userIds = userState.selectedUsers.map((user) => user.id);

        let removedRoles = false;
        await Promise.all(
            userIds.map(async (userId: any) => {
                return await deleteUser(userId)
                    .then(() => {
                        setOpen(false);
                    })
                    .catch(async (err: any) => {
                        if (err.response.data?.message === 'User has roles for different events') {
                            return await onDelete(userId)
                                .then(() => {
                                    removedRoles = true;
                                })
                                .catch(() => {
                                    enqueueSnackbar('User deletion unsuccessful!', {
                                        variant: 'warning',
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        },
                                    });
                                });
                        } else {
                            enqueueSnackbar('User deletion unsuccessful!', {
                                variant: 'warning',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                            });
                        }
                    });
            }),
        ).then(() => {
            if (removedRoles) {
                setIsEventCodeRemoved(true);
            } else {
                enqueueSnackbar('User deletion successful!', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
                onCompleteCallback();
            }
        });
    };

    return (
        <>
            <ActionButton id="delete-user" onClick={toggleOpen} text={'Delete User'} />
            <ActionConfirmDialog
                open={isOpen}
                onClose={toggleOpen}
                onConfirm={onConfirm}
                title={'Delete User'}
                content={
                    'The entire user account and every role in any domain will be deleted. All user information incl. chats, business cards, and achievements will be deleted and cannot be restored. Do you want to proceed?'
                }
            ></ActionConfirmDialog>
            <CompleteDialog
                title="Delete user roles"
                successMessages={[
                    'One or more selected users have more than one domain assignment. They cannot be deleted but their domain assignment was removed.',
                ]}
                open={isEventCodeRemoved}
                onClose={handleCloseCompleteDialog}
            />
        </>
    );
};

export default DeleteUserContainer;
