import React, { FC, useState } from 'react';
import UserDialog, { UserDialogOptions } from '../../Components/Dialogs/UserDialog/UserDialog';
import { editUser } from '../../Services/http/user.service';
import ActionButton from '../../Components/ActionButton/ActionButton';
import { User } from '../../Models/user.model';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import { useSnackbar } from 'notistack';

interface Props {
    onCompleteCallback: () => void;
}

const EditUserActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const { state, dispatch } = useUserManagement();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    const onSubmit = async (user: Partial<User>, userDialogOptions: UserDialogOptions) => {
        // ...we need to assert that the field exists in order to use Partial<User>
        const { editUserId } = userDialogOptions;
        await editUser(
            {
                email: user.email!,
                firstname: user.firstname!,
                lastname: user.lastname!,
                job_title: user.job_title!,
                company: user.company!,
                active: user.active!,
                not_tracked: user.not_tracked!,
            },
            editUserId!,
        )
            .then(() => {
                onCompleteCallback();
                onToggle();
                enqueueSnackbar('User was updated', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            })
            .catch((e) => {
                if (e.response?.data?.response?.message) {
                    setErrorMessage(e.response.data.message);
                }
            });
    };

    const onToggle = () => {
        if (!open) {
            const { users, selectedUsers } = state;
            users.forEach((user) => {
                // ...button is only rendered when one user is selected
                if (user.id === selectedUsers[0].id) {
                    setSelectedUser(user);
                }
            });
        } else {
            setSelectedUser(null);
        }
        setErrorMessage('');
        setOpen(!open);
    };

    return (
        <>
            <ActionButton
                text={'Edit User'}
                onClick={onToggle}
                icon={<BorderColorIcon />}
                id="selected-user-edit-user"
            />
            {open && (
                <UserDialog
                    onSubmit={onSubmit}
                    toggleDialog={onToggle}
                    errorMessage={errorMessage}
                    editUser={selectedUser}
                />
            )}
        </>
    );
};

export default EditUserActionContainer;
