export const en = {
    translation: {
        loginPage: {
            title: 'STAR Admin Portal',
            domain: 'domain',
        },
        logInForm: {
            titleContainer: {
                welcomeMessage: 'Welcome to the',
                projectName: 'STAR Admin Portal',
            },
            button: {
                login: 'Log in',
                accentureLogIn: 'Log In via Accenture',
                linkedInLogIn: 'Log In with LinkedIn',
                pmiLogIn: 'Log In via PMI',
                rocheLogIn: 'Log In via Roche',
                facebookLogIn: 'Log In via Facebook',
                ktbLogin: 'Log In via KTB',
                googleLogIn: 'Sign in with Google',
                createAccount: 'Create Account',
                forgotPassword: 'Forgot password',
            },
            email: {
                placeholder: 'Username',
            },
            password: {
                placeholder: 'Password',
            },
            eventCode: {
                placeholder: 'Domain',
            },
            consentRequirements: {
                personalData:
                    'The protection of your personal data is very important to Accenture and any personal data you provide through this tool may be processed by Accenture. Accenture is committed to keeping your personal data secure and processing it in accordance with applicable privacy laws and our internal policies.',
                personalData2:
                    'Personal data Accenture processes may be transferred or be accessible internationally throughout Accenture’s global business and between its entities and affiliates, and third parties.',
                personalData3:
                    ' Before using this tool, Accenture invites you to carefully read its ',
                importantInformation:
                    ', which includes important information on why and how Accenture is processing your personal data.',
                privacyStatement: 'privacy statement',
                message: 'I consent to the Accenture ',
                privacyPolicy: 'Privacy Policy',
                message2: '',
            },
            cookieConsent: {
                cookieMessage:
                    'Accenture is using local storage technology (similar to functional cookies) on this site. This technology temporarily caches some limited information on your system ensuring a swift and smooth user experience. Any cookies and similar technology are used in accordance with Accenture’s ',
                cookiePolicy: 'cookies policy.',
            },
            localStorageConsent: {
                localStorageMessage:
                    'I consent with the use of the local storage technology (similar to functional cookies).',
            },
            loginTitles: {
                loginViaEmail: 'Log In via email',
                login: 'Log In',
            },
        },
        homePage: {
            domainManagement: 'Domains',
            linkManagement: 'Reality Linking',
        },
        userManagement: {
            allDomain: 'All domains',
            domainAndRole: 'Domain and Role Assignment',
            domain: 'domain',
            domainHeader: 'Domain',
            chooseDomain: 'Choose domain',
            domainAdmin: 'domain-admin',
            platformAdmin: 'platform-admin',
            deleteDomain: 'Delete domain assignment',
        },
        domainManagement: {
            domainDialog: 'a Domain',
            buttonDomain: 'Create Domain',
            editDomain: 'Edit Domain',
            refreshDomain: 'Refresh Domain',
        },
        linkManagement: {
            title: 'Current Available Reality Linkings',
            button: 'Create Reality Linking',
            access: 'Reality Linking Access',
        },
    },
};
