import { Checkbox, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import { BaseRoom, Room } from '../../Models/socket.model';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';

const roomTypes = ['external', 'room', 'lobby'] as const;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            padding: theme.spacing(1, 3),
            '& .MuiTypography-root': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        formContainer: {
            '& .MuiTextField-root': {
                width: 300,
            },

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: theme.spacing(3),
        },
        closeButton: {
            color: theme.palette.grey[500],
            transform: 'translateX(25%)',
        },
        dialogContent: {
            '& div': {
                marginBottom: theme.spacing(1),
            },
        },
        active: {
            color: 'grey',
        },
        dropdown: {
            margin: '10px 0px',
        },
    }),
);

interface Props {
    room?: Room;
    errorMessage?: string;
    handleClose: () => void;
    // Have roomSettings be with an optional id
    handleSubmit: (roomSettings: BaseRoom) => void;
}

export const ManageRoomDialog: FC<Props> = ({ room, errorMessage, handleClose, handleSubmit }) => {
    const classes = useStyles();

    const [id] = useState<number | undefined>(room?.id);
    const [name, setName] = useState<string>(room?.name || '');
    const [link, setLink] = useState<string>(room?.link || '');
    const [type, setType] = useState<string>(room?.type || roomTypes[0]);
    const [multiplayer, setMultiplayer] = useState<boolean>(false);
    const [restricted, setRestricted] = useState<boolean>(room?.restricted || false);
    const [distribution, setDistribution] = useState<boolean>(room?.distribution || false);

    const [errors, setErrors] = useState({
        name: '',
        link: '',
        // type: '',
        // multiplayer: '',
        // restricted: '',
        // distribution: '',
    });

    const handleValidation = () => {
        const newErrors = { ...errors };
        let formIsValid = true;

        //Name
        if (name === '') {
            formIsValid = false;
            newErrors.name = 'Cannot be empty';
        } else {
            newErrors.name = '';
        }

        //Link
        if (link === '') {
            formIsValid = false;
            newErrors.link = 'Cannot be empty';
        } else {
            newErrors.link = '';
        }

        setErrors(newErrors);
        return formIsValid;
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
            <DialogTitle className={classes.dialogTitle} id="customized-dialog-title">
                <Typography>{id ? 'Edit' : 'Create'} a reality linking</Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent} dividers>
                <form className={classes.formContainer} noValidate autoComplete="off">
                    <TextField
                        required
                        label="Name"
                        value={name}
                        id="create-reality-linking-dialog-name"
                        onChange={(e) => setName(e.target.value)}
                        error={errors.name !== ''}
                        helperText={errors.name}
                    />
                    <TextField
                        required
                        autoComplete="new-password"
                        label="Link"
                        value={link}
                        id="create-reality-linking-dialog-link"
                        onChange={(e) => setLink(e.target.value)}
                        error={errors.link !== ''}
                        helperText={errors.link}
                    />
                    <Autocomplete
                        className={classes.dropdown}
                        id="tags-outlined"
                        options={[...roomTypes]}
                        value={type || roomTypes[0]}
                        onChange={(e, newValue) => setType(newValue)}
                        filterSelectedOptions
                        disableClearable
                        renderInput={(params) => (
                            <TextField {...params} required variant="outlined" label="Type" />
                        )}
                    />
                    {false && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    className={classes.active}
                                    checked={multiplayer}
                                    id="create-reality-linking-dialog-restricted-checkbox"
                                />
                            }
                            className={classes.active}
                            label="Multiplayer"
                            labelPlacement="end"
                            onChange={() => setMultiplayer(!multiplayer)}
                        />
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="secondary"
                                className={classes.active}
                                checked={restricted}
                                id="create-reality-linking-dialog-distribution-checkbox"
                            />
                        }
                        className={classes.active}
                        label="Restricted"
                        labelPlacement="end"
                        onChange={() => setRestricted(!restricted)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="secondary"
                                className={classes.active}
                                checked={distribution}
                            />
                        }
                        className={classes.active}
                        label="Distribution"
                        labelPlacement="end"
                        onChange={() => setDistribution(!distribution)}
                    />
                    {errorMessage !== '' ? (
                        <FormHelperText error>{errorMessage}</FormHelperText>
                    ) : undefined}
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    id="create-reality-linking-dialog-cancel"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        if (handleValidation()) {
                            handleSubmit({
                                id,
                                name,
                                link,
                                type,
                                restricted,
                                distribution,
                            });
                        }
                    }}
                    color="primary"
                    id="create-reality-linking-dialog-save"
                >
                    {id ? 'Update' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
