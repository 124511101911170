import React, { FC, useState } from 'react';
import ActionButton from '../../Components/ActionButton/ActionButton';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import { UPDATE_USER_LIST } from '../../Contexts/userManagementContext';
import ActionConfirmDialog from '../../Components/Dialogs/ActionConfirmDialog/ActionConfirmDialog';
import CompleteDialog from '../../Components/Dialogs/ActionCompleteDialog/ActionCompleteDialog';
import { removeAllRolesKeepEventCodes } from '../../Services/http/auth.service';

interface Props {
    onCompleteCallback: () => void;
}

const DeleteAllUserRolesContainer: FC<Props> = ({ onCompleteCallback }) => {
    const { state, dispatch } = useUserManagement();
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleConfirmClick = async () => {
        const selectedUserIds = state.selectedUsers.map((selectedUser) => {
            // The displayed roles come from 'users', not 'selectedUsers'
            state.users.forEach((user) => {
                if (user.id === selectedUser.id) {
                    user.roles?.forEach((role) => {
                        if (role.event_code) {
                            role.role = '';
                            role.room = '';
                        }
                    });
                }
            });
            return selectedUser.id;
        });
        removeAllRolesKeepEventCodes(selectedUserIds)
            .then(() => {
                setIsSuccess(true);
                dispatch({ type: UPDATE_USER_LIST, payload: { users: state.users } });
                setIsConfirmDialogOpen(false);
                toggleCompleteDialog();
            })
            .catch((err: any) => {
                const userId = JSON.parse(err?.response?.config?.data)?.user_id;
                console.error(`Error deleting roles for user ${userId}`);
                setIsSuccess(false);
            });
    };

    const toggleConfirmDialog = () => {
        setIsConfirmDialogOpen(!isConfirmDialogOpen);
    };

    const toggleCompleteDialog = () => {
        setIsCompleteDialogOpen(!isCompleteDialogOpen);
    };

    const handleCloseCompleteDialog = () => {
        if (onCompleteCallback) {
            onCompleteCallback();
        }
        toggleCompleteDialog();
    };

    return (
        <>
            <ActionButton
                icon
                onClick={toggleConfirmDialog}
                text={'Delete all user roles'}
                id="selected-user-delete-all-user-roles"
            />
            <ActionConfirmDialog
                open={isConfirmDialogOpen}
                onClose={toggleConfirmDialog}
                onConfirm={handleConfirmClick}
                title={'Delete all roles'}
                content={
                    'The selected user(s) will lose all domain roles. Do you want to continue?'
                }
            />
            <CompleteDialog
                title="Delete user roles"
                successMessages={
                    isSuccess ? ['All user roles of the selected user(s) have been deleted.'] : []
                }
                errorMessages={isSuccess ? [] : ['An error occurred.']}
                open={isCompleteDialogOpen}
                onClose={handleCloseCompleteDialog}
            />
        </>
    );
};

export default DeleteAllUserRolesContainer;
