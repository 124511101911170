import React, { createContext, Dispatch, FC, Reducer, useReducer } from 'react';
import { User } from '../Models/user.model';
import { EventRoomCell, RoomCell } from '../Models/table-cells.model';
import { Event } from '../Models/event.model';
import useLogger from '../Hooks/Middleware/userLogger';

// Actions
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';
export const UPDATE_SELECTED_USERS = 'UPDATE_SELECTED_USERS';
export const UPDATE_ROLE_ID_LIST = 'UPDATE_ROLE_ID_LIST';

export const UPDATE_ROOMS = 'UPDATE_ROOMS';
export const UPDATE_EVENTS = 'UPDATE_EVENTS';

// Action interface
interface UpdateUserListAction {
    type: typeof UPDATE_USER_LIST;
    payload: {
        users: User[];
    };
}
interface UpdateSelectedUsersAction {
    type: typeof UPDATE_SELECTED_USERS;
    payload: { selectedUsers: User[] };
}

interface UpdateRoomsAction {
    type: typeof UPDATE_ROOMS;
    payload: { rooms: EventRoomCell[] };
}
interface UpdateRoleIdAction {
    type: typeof UPDATE_ROLE_ID_LIST;
    payload: { availableRoleIds: any[] };
}

interface UpdateEventsAction {
    type: typeof UPDATE_EVENTS;
    payload: { events: Event[] };
}

// Actions type
type UserManagementActions =
    | UpdateUserListAction
    | UpdateSelectedUsersAction
    | UpdateRoomsAction
    | UpdateRoleIdAction
    | UpdateEventsAction;

// State
type State = {
    users: User[];
    selectedUsers: User[];
    rooms: EventRoomCell[];
    availableRoleIds: any[];
    events: Event[];
};

const initialState: State = {
    users: [],
    selectedUsers: [],
    rooms: [],
    availableRoleIds: [],
    events: [],
};

export const UserManagementContext = createContext<{
    state: State;
    dispatch: Dispatch<UserManagementActions>;
}>({
    state: initialState,
    dispatch: () => {},
});

const userManagementReducer: Reducer<State, UserManagementActions> = (
    state: State,
    action: UserManagementActions,
) => {
    switch (action.type) {
        case UPDATE_USER_LIST: {
            return {
                ...state,
                users: [...action.payload.users],
            };
        }
        case UPDATE_SELECTED_USERS: {
            return { ...state, selectedUsers: action.payload.selectedUsers };
        }
        case UPDATE_ROOMS: {
            return {
                ...state,
                rooms: action.payload.rooms,
            };
        }
        case UPDATE_ROLE_ID_LIST: {
            return {
                ...state,
                availableRoleIds: [
                    ...action.payload.availableRoleIds
                        .filter(
                            (availableRoleIds) =>
                                !['exhibitor', 'room-admin'].includes(availableRoleIds.name),
                        )
                        .map((availableRoleIds) =>
                            availableRoleIds.name === 'admin'
                                ? { ...availableRoleIds, name: 'platform-admin' }
                                : availableRoleIds.name === 'event-admin'
                                ? { ...availableRoleIds, name: 'domain-admin' }
                                : availableRoleIds,
                        ),
                ],
            };
        }
        case UPDATE_EVENTS: {
            return {
                ...state,
                events: action.payload.events,
            };
        }
        default:
            return state;
    }
};

export const UserManagementContextProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(useLogger(userManagementReducer), initialState);

    const value = { state, dispatch };

    return (
        <UserManagementContext.Provider value={value}>{children}</UserManagementContext.Provider>
    );
};
