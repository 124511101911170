import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    LinearProgress,
    LinearProgressProps,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { CardCreation } from '../../Components/CardCreation/CardCreation';
import { InputField } from '../../Components/InputField/InputField';
import { UPDATE_GEN_AI } from '../../Contexts/useCaseContext';
import useCaseManagement from '../../Hooks/UseCaseManagement/UseCaseManagement';
import { Event } from '../../Models/event.model';
import { fetchEvents } from '../../Services/http/event.service';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subCard: {
            backgroundColor: '#2B2B2E',
            borderRadius: '0.25rem',
            borderColor: '#484848',
            border: '0.5px solid',
            padding: theme.spacing(2),
            height: '100%',
            color: theme.palette.text.primary,
            cursor: 'pointer',
            flex: 2,
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            flex: 4,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(2),
            justifyContent: 'space-between',
            width: '100%',
        },
    }),
);

const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const LinearProgressWithLabel = (props: LinearProgressProps) => {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value ?? 0,
                )}%`}</Typography>
            </Box>
        </Box>
    );
};

export const GenAiInfo: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { state, dispatch } = useCaseManagement();

    const [domainsList, setDomainList] = useState<Array<Event>>([]);

    useEffect(() => {
        fetchEvents(1, 100, 'name', 'asc', '')
            .then((res: any) => setDomainList(res.data.events))
            .catch((e) => {
                console.error(e);
            });
    }, []);

    function handleChangeUseCaseName(newUseCaseName: string) {
        updateGenAiInfo(
            newUseCaseName,
            state.genAi.description,
            state.genAi.domain,
            state.genAi.maxBudget,
            state.genAi.isActive,
        );
    }

    function handleChangeUseCaseDescription(newUseCaseDescription: string) {
        updateGenAiInfo(
            state.genAi.name,
            newUseCaseDescription,
            state.genAi.domain,
            state.genAi.maxBudget,
            state.genAi.isActive,
        );
    }

    function handleChangeDomainAssignment(newDomainAssignment: string) {
        updateGenAiInfo(
            state.genAi.name,
            state.genAi.description,
            newDomainAssignment,
            state.genAi.maxBudget,
            state.genAi.isActive,
        );
    }

    function handleChangeMaxBudget(newMaxBudget: string) {
        updateGenAiInfo(
            state.genAi.name,
            state.genAi.description,
            state.genAi.domain,
            Number(newMaxBudget),
            state.genAi.isActive,
        );
    }

    function handleChangeIsActive(newIsActive: boolean) {
        updateGenAiInfo(
            state.genAi.name,
            state.genAi.description,
            state.genAi.domain,
            state.genAi.maxBudget,
            newIsActive,
        );
    }

    function updateGenAiInfo(
        newUseCaseName: string,
        newUseCaseDescription: string,
        newDomainAssignment: string,
        newMaxBudget: number | null,
        newIsActive: boolean,
    ) {
        dispatch({
            type: UPDATE_GEN_AI,
            payload: {
                name: newUseCaseName,
                description: newUseCaseDescription,
                domain: newDomainAssignment,
                maxBudget: newMaxBudget,
                isActive: newIsActive,
            },
        });
    }

    return (
        <CardCreation
            title="Gen AI use Case Information"
            popoverContent="All information about use case"
            topComponent={
                <FormControlLabel
                    control={
                        <CustomSwitch
                            checked={state.genAi.isActive}
                            onChange={(e) => handleChangeIsActive(e.target.checked)}
                            name="activate"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label="Activated"
                    labelPlacement="start"
                />
            }
        >
            <div className={classes.row}>
                <div className={classes.column}>
                    <InputField
                        placeHolder="Name:"
                        value={state.genAi.name}
                        onChange={(v) => handleChangeUseCaseName(v)}
                        autoComplete="name"
                    />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="Domain-assignment-select">Domain Assignment</InputLabel>
                        <Select
                            labelId="Domain-assignment-select"
                            id="Domain-assignment-select"
                            value={state.genAi.domain}
                            onChange={(e) => handleChangeDomainAssignment(e.target.value)}
                            IconComponent={ExpandMoreIcon}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {domainsList.map((domain: Event) => {
                                return (
                                    <MenuItem value={domain.event_code} key={domain.event_code}>
                                        {domain.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <InputField
                        placeHolder="Description:"
                        value={state.genAi.description}
                        onChange={(v) => handleChangeUseCaseDescription(v)}
                        autoComplete="description"
                        multiline
                    />
                </div>
                <div className={classes.subCard}>
                    <div className={classes.column}>
                        <TextField
                            label="Max budget"
                            sx={{ m: 1, width: '25ch' }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            variant="standard"
                            inputProps={{ type: 'number' }}
                            value={state.genAi.maxBudget !== null ? state.genAi.maxBudget : ''}
                            onChange={(e) => handleChangeMaxBudget(e.target.value)}
                        />
                    </div>
                    {state.genAi.maxBudget && (
                        <div className={classes.column}>
                            <Typography>Running cost:</Typography>
                            <LinearProgressWithLabel value={0} />
                        </div>
                    )}
                </div>
            </div>
        </CardCreation>
    );
};
