import React, { FC, useState } from 'react';
import ActionButton from '../../Components/ActionButton/ActionButton';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { editEvent } from '../../Services/http/event.service';
import EventDialog from '../../Components/Dialogs/EventDialog/EventDialog';
import { Event } from '../../Models/event.model';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

interface Props {
    onCompleteCallback: () => void;
}

const EditEventActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const { state, dispatch } = useEventManagement();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    const onSubmit = async (event: Event) => {
        await editEvent(event)
            .then(() => {
                onCompleteCallback();
                onToggle();
                enqueueSnackbar('Event was updated', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            })
            .catch((e) => {
                if (e.response?.data?.message) {
                    setErrorMessage(e.response.data.message);
                }
            });
    };

    const onToggle = () => {
        if (!open) {
            const { events, selectedEvents } = state;
            events.forEach((event) => {
                // ...button is only rendered when one event is selected
                if (event.event_code === selectedEvents[0].event_code) {
                    setSelectedEvent(event);
                }
            });
        } else {
            setSelectedEvent(null);
        }
        setErrorMessage('');
        setOpen(!open);
    };

    return (
        <>
            <ActionButton
                onClick={onToggle}
                text={t('domainManagement.editDomain')}
                icon={<BorderColorIcon />}
                id="domains-edit-domain"
            />
            {open && (
                <EventDialog
                    onSubmit={onSubmit}
                    toggleDialog={onToggle}
                    errorMessage={errorMessage}
                    editEvent={selectedEvent}
                />
            )}
        </>
    );
};

export default EditEventActionContainer;
