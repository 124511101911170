import React, { FC, useState } from 'react';
import { Button, Box, Checkbox, createStyles, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { ProviderResponse } from '../../Models/auth.model';
import { setLocalStorageItemValue } from '../../Services/local-storage.service';
import * as authService from '../../Services/http/auth.service';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';

interface Props {
    onSubmit: Function;
    loginFailMsg: string | undefined;
    loginOauthFailMsg: string | undefined;
    providers?: ProviderResponse;
}

const useStyles = (providers?: any) =>
    makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                '& .MuiInput-underline': {
                    borderBottom: '1px solid white',
                },
                '& .MuiFormControl-root': {
                    width: '100%',
                },
                '& .MuiTypography-root': {
                    fontFamily: 'Graphik',
                },
                '& .MuiButton-root': {
                    textTransform: 'none',
                    backgroundColor: '#e0e0e0',
                },
                '& .MuiCheckbox-root': {
                    color: '#e0e0e0',
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontFamily: 'Graphik',
            },
            mainContainer: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: theme.spacing(10, 2),
                width: '450px',
                backgroundColor: '#7500C0',
            },
            titleContainer: {
                margin: theme.spacing(5, 0),
                width: '70%',
            },
            titleContainerChild: {
                fontWeight: 400,
                fontSize: 20,
                lineHeight: '22px',
                width: '100%',
                textAlign: 'left',
            },
            titleContainerChildSecond: {
                fontWeight: 700,
                fontSize: 36,
                lineHeight: '36px',
                width: '100%',
                textAlign: 'justify',
            },
            buttonContainer: {
                margin: theme.spacing(0, 0, 3, 0),
                width: '70%',
            },
            providerButton: {
                margin: theme.spacing(2, 0, 2, 0),
                width: '100%',
            },
            inputContainer: {
                marginTop: theme.spacing(5, 0),
                marginBottom: theme.spacing(3, 0),
                width: '70%',
            },
            checkboxContainer: {
                margin: theme.spacing(2, 0, 5, 0),
                width: '75%',
                alignItems: 'center',
            },
            headerTitleThird: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: theme.spacing(7, 2),
                backgroundColor: '#1E1E1E',
                fontWeight: 500,
                fontSize: 12,
                lineHeight: '10px',
                width: '100%',
                textAlign: 'justify',
            },
            input: {
                color: 'white',
                fontWeight: 700,
                fontSize: '26px',
                lineHeight: '36px',
                width: '100%',
                textAlign: 'justify',
                margin: theme.spacing(1, 0),
                boxSizing: 'border-box',
            },
            inputCredentials: {
                color: 'white',
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '36px',
                width: '100%',
                textAlign: 'justify',
                margin: theme.spacing(1, 0),
                boxSizing: 'border-box',
            },
            smallCaption: {
                fontSize: 12,
                fontWeight: 500,
                lineHeight: '17px',
                width: '100%',
                textAlign: 'center',
            },
            checkboxContainerTypography: {
                fontSize: 12,
                fontWeight: 700,
                lineHeight: '12px',
                alignItems: 'center',
            },
            checkboxContainerBox: {
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                textAlign: 'left',
                alignItems: 'center',
                height: '25px',
            },

            checkboxContainerSpan: {
                flex: 1,
            },
            checkboxContainerLink: {
                color: 'white',
            },
            button: {
                height: '30px',
                width: '100%',
                textTransform: 'none',
                transition: 'all 0.4s ease-in-out',
            },
            buttonSpan: {
                fontSize: 16,
                fontWeight: 800,
                lineHeight: '12px',
                alignItems: 'center',
            },
            loginButton: {
                marginTop: theme.spacing(6),
            },
            errorStatus: {
                marginTop: theme.spacing(3),
                color: '#b71c1c',
            },
            errorStatusOauth: {
                color: '#b71c1c',
            },
            loginEmailContainer: {
                margin: theme.spacing(0, 0, 3, 0),
                width: '70%',
                transition: 'all 0.4s ease-in-out',
            },
            divHide: {
                opacity: 0,
                height: 0,
                overflow: 'hidden',
            },
            divShow: {
                opacity: 1,
                height: 'auto',
            },
        });
    });

const LoginForm: FC<Props> = ({ onSubmit, loginFailMsg, loginOauthFailMsg, providers }) => {
    const classes = useStyles(providers?.providers)();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [eventName, setEventName] = useState('');
    const [privacySetting, setPrivacySetting] = useState<boolean>(false);
    const [localStorageSetting, setLocalStorageSetting] = useState<boolean>(false);
    const [showEmailLogin, setShowEmailLogin] = useState<boolean>(false);
    const { t } = useTranslation();

    const onClick = (e: any) => {
        e.preventDefault();
        onSubmit(username, password, eventName);
    };

    // Constants initialization
    const OAuth2URL = `${authService.servicePrefix}/oauth2`;
    const privacyURL = 'https://www.accenture.com/us-en/about/privacy-policy';
    const cookieLinkClick =
        'https://www.accenture.com/us-en/support/company-cookies-similar-technology';

    const label = { 'aria-label': 'Checkbox' };

    const showEmailLoginContainer = () => {
        setShowEmailLogin(!showEmailLogin);
    };

    const setEventLocalStorage = () => {
        setLocalStorageItemValue('eventCode', eventName);
    };

    const handleChange = () => {
        setPrivacySetting(!privacySetting);
    };

    const handleLocalStorage = () => {
        setLocalStorageSetting(!localStorageSetting);
    };

    // On SSO login button click
    const onSSOLogInClick = (SSOType: string) => {
        const url = `${OAuth2URL}?frontend=${authService.FrontendTypes.ADMIN}&provider=${SSOType}`;
        return url;
    };

    return (
        <>
            <div className={classes.root}>
                <div className={classes.mainContainer}>
                    <div className={classes.titleContainer}>
                        <Typography>
                            <div className={classes.titleContainerChild}>
                                {t('logInForm.titleContainer.welcomeMessage')}
                            </div>
                        </Typography>
                        <Typography>
                            <div className={classes.titleContainerChildSecond}>
                                {t('logInForm.titleContainer.projectName')}
                            </div>
                        </Typography>
                    </div>
                    <div className={classes.inputContainer}>
                        <TextField
                            placeholder={t('logInForm.eventCode.placeholder')}
                            value={eventName}
                            id="login-form-event-code"
                            InputProps={{ classes: { input: classes.input } }}
                            onChange={(e) => setEventName(e.target.value)}
                            autoComplete={'on'}
                        />
                    </div>
                    <div className={classes.checkboxContainer}>
                        <Typography className={`${classes.checkboxContainerTypography}`}>
                            <Box className={`${classes.checkboxContainerBox}`}>
                                <Checkbox
                                    value={privacySetting}
                                    onChange={handleChange}
                                    inputProps={label}
                                    id="privacy-policy-link-checkbox"
                                    color="default"
                                />
                                <div className={classes.checkboxContainerSpan}>
                                    <span>{t('logInForm.consentRequirements.message')}</span>
                                    <Link
                                        target="_blank"
                                        className={`${classes.checkboxContainerLink}`}
                                        underline="always"
                                        href={privacyURL}
                                        key={privacyURL}
                                        id="privacy-policy-link"
                                    >
                                        {t('logInForm.consentRequirements.privacyPolicy')}
                                    </Link>
                                </div>
                            </Box>
                            <Box className={`${classes.checkboxContainerBox}`}>
                                <Checkbox
                                    value={localStorageSetting}
                                    onChange={handleLocalStorage}
                                    inputProps={label}
                                    id="local-storage-login-checkbox"
                                    color="default"
                                />
                                <span className={`${classes.checkboxContainerSpan}`}>
                                    {t('logInForm.localStorageConsent.localStorageMessage')}
                                </span>
                            </Box>
                        </Typography>
                    </div>
                    <div className={`${classes.buttonContainer}`}>
                        {loginOauthFailMsg && (
                            <div>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.errorStatusOauth}
                                >
                                    {loginOauthFailMsg}
                                </Typography>
                            </div>
                        )}

                        {providers &&
                            providers.providers.map((provider) => {
                                return (
                                    <div
                                        key={provider.name}
                                        className={`${classes.providerButton}`}
                                    >
                                        <Button
                                            id={provider.button_description}
                                            variant="contained"
                                            className={`${classes.button}`}
                                            disabled={!(privacySetting && localStorageSetting)}
                                            onClick={() => setEventLocalStorage()}
                                            href={onSSOLogInClick(provider.name)}
                                        >
                                            <Typography className={`${classes.buttonSpan}`}>
                                                {provider.button_text}
                                            </Typography>
                                        </Button>
                                    </div>
                                );
                            })}
                        {!showEmailLogin && (
                            <div className={`${classes.providerButton}`}>
                                <Button
                                    id="login-via-email"
                                    variant="contained"
                                    className={`${classes.button}`}
                                    onClick={() => showEmailLoginContainer()}
                                >
                                    <Typography className={`${classes.buttonSpan}`}>
                                        {t('logInForm.loginTitles.loginViaEmail')}
                                    </Typography>
                                </Button>
                            </div>
                        )}
                    </div>
                    <div
                        className={`${classes.loginEmailContainer} 
                            ${showEmailLogin ? classes.divShow : classes.divHide}`}
                    >
                        <form noValidate onSubmit={onClick}>
                            <div>
                                <TextField
                                    placeholder={t('logInForm.email.placeholder')}
                                    value={username}
                                    id="login-form-email"
                                    onChange={(e) => setUsername(e.target.value)}
                                    InputProps={{
                                        classes: { input: classes.inputCredentials },
                                    }}
                                    autoComplete={'email'}
                                />
                            </div>
                            <div>
                                <TextField
                                    placeholder={t('logInForm.password.placeholder')}
                                    type="password"
                                    id="login-form-password"
                                    value={password}
                                    InputProps={{
                                        classes: { input: classes.inputCredentials },
                                    }}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete={'current-password'}
                                />
                            </div>
                            {loginFailMsg && (
                                <div>
                                    <Typography variant="subtitle2" className={classes.errorStatus}>
                                        {loginFailMsg}
                                    </Typography>
                                </div>
                            )}
                            <div className={classes.loginButton}>
                                <Button
                                    variant="contained"
                                    type={'submit'}
                                    id="login-form-submit"
                                    className={`${classes.button}`}
                                    onClick={onClick}
                                    disabled={!(privacySetting && localStorageSetting)}
                                >
                                    <Typography className={`${classes.buttonSpan}`}>
                                        {t('logInForm.loginTitles.login')}
                                    </Typography>
                                </Button>
                            </div>
                        </form>
                    </div>
                    <div className={classes.headerTitleThird}>
                        <Box>
                            <div>{t('logInForm.consentRequirements.personalData')} </div>
                            <div>{t('logInForm.consentRequirements.personalData2')} </div>
                            <div>
                                {t('logInForm.consentRequirements.personalData3')}
                                <Link
                                    target="_blank"
                                    underline="always"
                                    href={privacyURL}
                                    key={privacyURL}
                                    id="privacy-policy-link"
                                >
                                    {t('logInForm.consentRequirements.privacyStatement')}
                                </Link>
                                {t('logInForm.consentRequirements.importantInformation')}
                            </div>
                        </Box>
                        <Box>
                            <div>
                                {t('logInForm.cookieConsent.cookieMessage')}
                                <Link
                                    target="_blank"
                                    className={`${classes.smallCaption}`}
                                    underline="always"
                                    href={cookieLinkClick}
                                    key={cookieLinkClick}
                                    id="cookie-policy-link"
                                >
                                    {t('logInForm.cookieConsent.cookiePolicy')}
                                </Link>
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginForm;
