import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, CircularProgress, Link, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Config from '../../config.json';
import {
    UPDATE_AGENT,
    UPDATE_AI_MODEL,
    UPDATE_GEN_AI,
    UPDATE_KNOWLEDGE,
    UPDATE_USE_CASE_ID,
} from '../../Contexts/useCaseContext';
import useCaseManagement from '../../Hooks/UseCaseManagement/UseCaseManagement';
import {
    FileTool,
    NewUseCase,
    SiloCreated,
    Tool,
    UseCaseCreated,
    UseCaseFile,
} from '../../Models/useCase.model';
import { createSilo, uploadFileInSilo } from '../../Services/http/silo.service';
import {
    createAgent,
    editAgent,
    getAgent,
    getAgentImage,
    uploadAgentImage,
} from '../../Services/http/useCase.services';
import { AgentForm } from './AgentInfo';
import { GenAiInfo } from './GenAIInfo';
import { KnowledgeForm } from './KnowledgeForm';
import { ModelAiForm } from './ModelAIForm';

interface Props {
    useCaseId: number | undefined;
}

const chatUrl: string = Config.VSF_K8S_CHAT_API_URL;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topButtonMenu: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        leftButtonsMenu: {
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1),
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: theme.spacing(2),
            flexWrap: 'wrap',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: theme.spacing(2),
            flex: 1,
            minWidth: '500px',
        },
    }),
);

export const UseCaseForm: React.FC<Props> = ({ useCaseId }) => {
    const classes = useStyles();
    const { state, dispatch } = useCaseManagement();
    const theme = useTheme();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(true);
    const [onSave, setOnSave] = useState<boolean>(false);
    const [errorMessageSaveUseCase, setErrorMessageSaveUseCase] = useState<string>();

    const buttonStyle = {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.background.default,
    };

    useEffect(() => {
        if (useCaseId === undefined || useCaseId === null) {
            setLoading(false);
        } else {
            getUseCaseInfo(useCaseId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useCaseId]);

    async function handleSubmit() {
        const actualUseCase: NewUseCase = buildUseCaseToSave();
        if (useCaseId !== undefined && useCaseId !== null) {
            editUseCase(useCaseId, actualUseCase);
        } else {
            createUseCase(actualUseCase);
        }
    }

    function onExit() {
        history.goBack();
    }

    async function createUseCase(newUseCase: any) {
        if (checkRequiredFields(false)) {
            setOnSave(true);
            await createAgent(newUseCase)
                .then(async (res) => {
                    const newUseCaseIdRes: number = res.data.id;
                    dispatch({
                        type: UPDATE_USE_CASE_ID,
                        payload: {
                            id: res.data.id,
                        },
                    });
                    enqueueSnackbar(`Create Use case was successful`, {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    if (state.agent.img !== null) {
                        sendImage(newUseCaseIdRes, state.agent.img);
                    }
                    state.knowledge.tools.forEach((newTool: Tool) => {
                        createNewSilo(newTool, newUseCaseIdRes);
                    });
                })
                .catch((e) => {
                    if (e.response?.data?.message) {
                        setErrorMessageSaveUseCase(e.response.data.message);
                    }
                    enqueueSnackbar(`Create Use case wasn't successful`, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .finally(() => {
                    setOnSave(false);
                });
        }
    }

    async function editUseCase(actualUseCaseId: number, actualUseCase: NewUseCase) {
        if (checkRequiredFields(true)) {
            setOnSave(true);
            await editAgent(actualUseCaseId, actualUseCase)
                .then(() => {
                    enqueueSnackbar('Edit Use case was successful', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .catch((e) => {
                    if (e.response?.data?.message) {
                        setErrorMessageSaveUseCase(e.response.data.message);
                    }
                    enqueueSnackbar(`Edit Use case wasn't successful`, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .finally(() => {
                    setOnSave(false);
                });
            if (state.agent.img) {
                await sendImage(actualUseCaseId, state.agent.img);
            }
            state.knowledge.tools.forEach((newTool: Tool) => {
                createNewSilo(newTool, actualUseCaseId);
            });
        }
    }

    async function getUseCaseInfo(useCaseId: number) {
        setLoading(true);
        await getAgent(useCaseId)
            .then(async (response) => {
                convertToLocalUseCase(response.data.agent);
            })
            .catch((e) => {
                if (e.response?.data?.message) {
                    console.error(e.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        await getAgentImage(useCaseId)
            .then((responseImg) => {
                convertBase64ToImage(responseImg.data, 'image.jpg')
                    .then((imageFile) => {
                        console.log(imageFile);
                    })
                    .catch((error) => {
                        console.error(
                            'Erreur lors de la conversion de la chaîne Base64 en image :',
                            error,
                        );
                    });
            })
            .catch((e) => {
                if (e.response?.data?.message) {
                    console.error(e.response.data.message);
                }
            });
    }

    async function convertBase64ToImage(base64String: string, fileName: string): Promise<File> {
        const base64Response = await fetch(base64String);
        const blob = await base64Response.blob();
        return new File([blob], fileName, { type: blob.type });
    }

    function buildUseCaseToSave(): NewUseCase {
        return {
            name: state.genAi.name,
            domain_assignment: state.genAi.domain,
            wbs: 'ABC12345',
            description: state.genAi.description,
            is_active: state.genAi.isActive,
            version: '0.1',
            budget: state.genAi.maxBudget ?? 0,
            system_behaviour_prompt: state.agent.agentDesc,
            character_name: state.agent.agentName,
            character_description: '',
            llm_provider_id: state.aiModel.provider_id ?? 0,
            llm_model_id: state.aiModel.model_id ?? 0,
            llm_region_id: state.aiModel.region_id ?? 0,
        };
    }

    function convertToLocalUseCase(useCase: UseCaseCreated) {
        if (useCase.id) {
            dispatch({
                type: UPDATE_USE_CASE_ID,
                payload: {
                    id: useCase.id,
                },
            });
        }
        dispatch({
            type: UPDATE_GEN_AI,
            payload: {
                name: useCase.name,
                description: useCase.description,
                domain: useCase.domain,
                maxBudget: useCase.budget,
                isActive: useCase.is_active,
            },
        });
        dispatch({
            type: UPDATE_AGENT,
            payload: {
                agentName: useCase.character_name,
                agentDesc: useCase.ai_data.system_prompt_components.system_behaviour_prompt,
                img: null,
            },
        });
        dispatch({
            type: UPDATE_AI_MODEL,
            payload: {
                provider_id: useCase.ai_data.main_llm.provider.id,
                model_id: useCase.ai_data.main_llm.model.id,
                region_id: useCase.ai_data.main_llm.region.id,
            },
        });
        dispatch({
            type: UPDATE_KNOWLEDGE,
            payload: {
                tools: useCase.ai_data.tools,
            },
        });
    }

    function checkRequiredFields(isEdit: boolean): boolean {
        const missingFields: Array<string> = [];
        if (state.genAi.name === '') missingFields.push('UseCase name');
        if (state.agent.agentName === '') missingFields.push('Agent name');
        if (!isEdit && state.agent.img == null) missingFields.push('Agent img');
        if (state.aiModel.provider_id == null) missingFields.push('provider');
        if (state.aiModel.model_id == null) missingFields.push('model');
        if (state.aiModel.region_id == null) missingFields.push('region');
        if (missingFields.length > 0) {
            enqueueSnackbar(`Missing fields: ${missingFields.join(',')}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        }
        return missingFields.length === 0;
    }

    async function createNewSilo(tool: Tool, id: number) {
        if (tool.id == null) {
            const newSilo: SiloCreated = {
                name: tool.name,
                description: tool.description,
            };
            await createSilo(id, newSilo)
                .then((res) => {
                    const siloId: number = res.data.id;
                    tool.files.forEach((fileTool: FileTool) => {
                        uploadOneFile(siloId, fileTool);
                    });
                })
                .catch((e) => {
                    console.error(e);
                });
        } else {
            tool.files.forEach((fileTool: FileTool) => {
                if (tool.id !== null && fileTool.id == null) {
                    uploadOneFile(tool.id, fileTool);
                }
            });
        }
    }

    async function uploadOneFile(indexSilo: number, fileTool: FileTool) {
        if (fileTool.file) {
            const newFile: UseCaseFile = {
                file: fileTool.file,
                description: fileTool.description,
            };
            await uploadFileInSilo(indexSilo, newFile)
                .then(() => {
                    enqueueSnackbar(`Upload file was successful`, {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .catch((e) => {
                    console.error(e);
                    enqueueSnackbar(`Upload file wasn't successful`, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                });
        }
    }

    async function sendImage(id: number, img: File) {
        await uploadAgentImage(id, img)
            .then((res) => {
                enqueueSnackbar(`Upload agent image was successful`, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar(`Upload agent image wasn't successful`, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            });
    }

    return (
        <>
            <div className={classes.topButtonMenu}>
                <Button
                    variant="contained"
                    onClick={onExit}
                    startIcon={<ArrowBackIcon />}
                    sx={buttonStyle}
                    id="create-use-case-back"
                >
                    Back
                </Button>
                <div className={classes.leftButtonsMenu}>
                    {state.id !== null && (
                        <Link href={`${chatUrl}/chat/${state.id}`}>
                            <Button
                                variant="contained"
                                sx={buttonStyle}
                                id="create-use-case-visitor-site"
                            >
                                Visitor site
                            </Button>
                        </Link>
                    )}
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={onSave}
                        sx={buttonStyle}
                        id="create-use-case-save"
                    >
                        Save
                    </Button>
                </div>
            </div>
            {loading && <CircularProgress />}
            {loading ? (
                <Typography color="error" variant="body1" sx={{ color: 'red' }}>
                    {errorMessageSaveUseCase}
                </Typography>
            ) : (
                <div className={classes.row}>
                    <div className={classes.column}>
                        <GenAiInfo />
                        <AgentForm />
                        <ModelAiForm />
                    </div>
                    <div className={classes.column}>
                        <KnowledgeForm />
                        {/* {state.id && <ChatTest useCaseId={state.id} />} */}
                    </div>
                </div>
            )}
        </>
    );
};
