import React, { FC, useState } from 'react';
import UserDialog, { UserDialogOptions } from '../../Components/Dialogs/UserDialog/UserDialog';
import { changeRole, removeAllRoles, signup } from '../../Services/http/auth.service';
import ActionButton from '../../Components/ActionButton/ActionButton';
import { User } from '../../Models/user.model';
import { useSnackbar } from 'notistack';
import UserRolesDialog from '../../Components/Dialogs/UserRolesDialog/UserRolesDialog';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import { SelectedUserRoles, UserRoles } from '../../Models/userRole.model';
import { UserCell, UserSubCell } from '../../Models/table-cells.model';
import { getLocalStorageItemValue } from '../../Services/local-storage.service';
import CompleteDialog from '../../Components/Dialogs/ActionCompleteDialog/ActionCompleteDialog';

interface Props {
    onCompleteCallback: () => void;
}

const CreateUserActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const rolePriority = [
        'platform-admin',
        'access-blocked',
        'domain-admin',
        'moderator',
        'room-admin',
        'exhibitor',
        'presenter',
        'visitor',
    ];
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [openRoleDialog, setOpenRoleDialog] = useState(false);
    const [userId, setUserId] = useState(0);
    const [userName, setUserName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [active, setActive] = useState(false);
    const [notTracked, setNotTracked] = useState(false);
    const [event, setEvent] = useState('');
    const [role, setRole] = useState('');
    const [room, setRoom] = useState('');
    const currentUser: any = JSON.parse(getLocalStorageItemValue('currentUser'));
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = React.useState(false);
    const onCompleteDialogToggle = () => {
        setIsCompleteDialogOpen(!isCompleteDialogOpen);
        onCompleteCallback();
    };
    const { state } = useUserManagement();
    const subCell: UserSubCell = {
        event,
        roles: [{ role, room }],
    };
    const cell: UserCell = {
        id: userId,
        username: userName,
        firstname: firstName,
        lastname: lastName,
        email,
        company,
        title,
        active,
        not_tracked: notTracked,
        subCells: [subCell],
    };
    const successMessage = (response: any, user: Partial<User>) => {
        let message = 'User creation was successful';
        if (response.data.error_code === 401.4) {
            message =
                'A user with this email and domain assignment already exists. No change to this user was made. Please change any user data via the "Edit User" function, if required.';
        } else if (response.data.error_code === 401.5) {
            message = `A user with this email already exists. No new user was created but the new domain ${user.event_code} was assigned to that user. Please note: There was no user data overwritten based on your input. Please change any user data via the "Edit User" function, if required.`;
        }
        onToggle();
        enqueueSnackbar(message, {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });
    };

    const onSubmit = async (user: Partial<User>, userDialogOptions: UserDialogOptions) => {
        const { sendEmail } = userDialogOptions;
        await signup(user as User, sendEmail!)
            .then((response) => {
                setUserId(response.data.user_data.id);
                setUserValues(user);
                successMessage(response, user);
                onCompleteCallback();
            })
            .catch((e) => {
                if (e.response?.data?.message) {
                    setErrorMessage(e.response.data.message);
                }
            });
    };

    const onToggle = () => {
        setErrorMessage('');
        setOpen(!open);
    };
    const setUserValues = (user: Partial<User>) => {
        setEvent(user.event_code!);
        setOpenRoleDialog(!openRoleDialog);
        setUserName(user.email!);
        setFirstName(user.firstname!);
        setLastName(user.lastname!);
        setEmail(user.email!);
        setCompany(user.company!);
        setTitle(user.job_title!);
        setActive(user.active!);
        setNotTracked(user.not_tracked!);
        setRole(user.roles![0].role);
        setRoom(user.roles![0].room);
    };
    const onSubmitRole = async (user: Partial<User>, userDialogOptions: UserDialogOptions) => {
        const { sendEmail } = userDialogOptions;
        await signup(user as User, sendEmail!)
            .then((response) => {
                successMessage(response, user);
                setUserId(response.data.user_data.id);
                setUserValues(user);
            })
            .catch((e) => {
                if (e.response?.data?.message) {
                    setErrorMessage(e.response.data.message);
                }
            });
    };
    const [successMessages, setSuccessMessages] = React.useState([] as string[]);
    const [errorMessages, setErrorMessages] = React.useState([] as string[]);
    const mapRoles = state.availableRoleIds.reduce((roleIdMapObj: any, eachRoleObj: UserRoles) => {
        roleIdMapObj[eachRoleObj.name] = eachRoleObj.id;
        return roleIdMapObj;
    }, {});
    const handleRolesUpdate = async (
        roles: any[],
        userId: number,
        eventCode: string,
        successResponses: any[],
        errResponses: any[],
    ) => {
        for (const role of roles) {
            await changeRole(
                mapRoles[role.role],
                userId,
                role.role !== 'platform-admin' ? eventCode : undefined,
                role.room ? Number(role.room) : undefined,
            )
                .then((res: any) => {
                    successResponses.push(`${role.role} assignment for ${userName} successful!`);
                    return res;
                })
                .catch((err: any) => {
                    errResponses.push(
                        `${role.role} assignment for ${userName} failed with Error: ${err?.response?.data?.message}`,
                    );
                    return err;
                });
        }
    };
    const onSave = async (selectedUserRoles: SelectedUserRoles | null) => {
        setSuccessMessages([]);
        setErrorMessages([]);
        const successResponses: any[] = [];
        const errResponses: any[] = [];
        //TODO: Remove this sort when endpoint accepts array
        selectedUserRoles!.roles?.sort((a: any, b: any) => {
            return rolePriority.indexOf(b.role) - rolePriority.indexOf(a.role);
        });

        if (!cell.subCells.some((_subCell) => _subCell.event === selectedUserRoles!.event_code)) {
            await handleRolesUpdate(
                selectedUserRoles!.roles!,
                selectedUserRoles!.id!,
                selectedUserRoles!.event_code!,
                successResponses,
                errResponses,
            );
        } else {
            await removeAllRoles(selectedUserRoles!.id!, selectedUserRoles!.event_code!)
                .then(async () => {
                    if (selectedUserRoles!.roles!.length === 0) {
                        successResponses.push(`Removing roles for ${userName} successful!`);
                    }
                    await handleRolesUpdate(
                        selectedUserRoles!.roles!,
                        selectedUserRoles!.id!,
                        selectedUserRoles!.event_code!,
                        successResponses,
                        errResponses,
                    );
                })
                .catch((err: any) => {
                    errResponses.push(
                        `Removing roles for ${userName} failed with Error: ${err?.response?.data?.message}`,
                    );
                });
        }

        setSuccessMessages(successResponses);
        setErrorMessages(errResponses);
        setOpenRoleDialog(false);
        setIsCompleteDialogOpen(!isCompleteDialogOpen);
    };

    return (
        <>
            <ActionButton
                text={'Create User'}
                onClick={onToggle}
                icon={null}
                id="users-create-user"
            />
            {open && (
                <UserDialog
                    onSubmit={onSubmit}
                    toggleDialog={onToggle}
                    errorMessage={errorMessage}
                    editUser={null}
                    onSubmitRole={onSubmitRole}
                />
            )}
            <UserRolesDialog
                open={openRoleDialog}
                onClose={() => {
                    setOpenRoleDialog(false);
                }}
                onConfirm={onSave}
                cell={cell}
                subCell={subCell}
            />
            <CompleteDialog
                title={'Role change '}
                successMessages={successMessages}
                errorMessages={errorMessages}
                open={isCompleteDialogOpen}
                onClose={onCompleteDialogToggle}
            />
        </>
    );
};

export default CreateUserActionContainer;
