import React, { FC, useCallback, useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { getPreferencesByCategory, postPreference } from '../../Services/http/event.service';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import { useSnackbar } from 'notistack';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';
import { Preference } from '../../Models/event.model';
import PreferenceRowColorPicker from '../../Components/PreferenceRow/PreferenceRowColorPicker';

const useStyles = makeStyles(() => ({
    bannersList: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
    },
    bannerRow: { width: '100%', marginBottom: '10px' },
    loaderWrapper: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transaction: 'translateX(-50%), translateY(-50%)',
    },
}));

const ColorPaletteAccordionContainer: FC = () => {
    const { state, dispatch } = useEventManagement();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [key, setKey] = useState(self.crypto.getRandomValues(new Uint32Array(1))[0]);
    const [colorPreferences, setColorPreferences] = useState<Preference[]>([]);

    const onCancel = async () => {
        setDisabled(true);
        await getColors();
    };

    const getColors = useCallback(async () => {
        const body = {
            event_code: state.eventCode,
            type: 'specific',
            category: 'color',
        };
        await getPreferencesByCategory(body).then((response: any) => {
            const colors = response.data;
            for (const preference of colors) {
                preference.initialValue = JSON.parse(JSON.stringify(preference.value));
            }
            setColorPreferences(colors);
            setKey(self.crypto.getRandomValues(new Uint32Array(1))[0]);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.eventCode]);

    useEffect(() => {
        getColors();
    }, [getColors]);

    const onSubmit = async () => {
        await Promise.all(
            colorPreferences.map(async (color: Preference) => {
                if (color.changed && color.id) {
                    const body = {
                        value: color.value,
                        name: color.name,
                        category: color.category,
                        event_code: state.eventCode,
                        type: 'string',
                    };
                    const id = color.id;
                    return await postPreference(body)
                        .then((response: any) => {
                            enqueueSnackbar(`${response.data.message} for ${color.name}`, {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                            });
                        })
                        .catch((e) => {
                            enqueueSnackbar(e.data.message, {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                            });
                        });
                }
            }),
        );
        await getColors();
    };

    const handleChange = (index: number, color: string) => {
        const arrayCopy = [...colorPreferences];
        if (arrayCopy[index].value !== color) {
            arrayCopy[index].value = color;
            arrayCopy[index].changed = true;
            setColorPreferences(arrayCopy);
            setDisabled(false);
        }
    };

    const renderColorInput = (color: Preference, index: number) => {
        return (
            <li key={`color-name-${color.name}`} className={classes.bannerRow}>
                <PreferenceRowColorPicker
                    preference={color}
                    onChange={(newValue) => handleChange(index, newValue)}
                />
            </li>
        );
    };

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    <strong>{EventAdministrationItems.COLOR_PALETTE}</strong>
                </Typography>
            </AccordionSummary>
            <List className={classes.bannersList} key={key}>
                {colorPreferences.map((color, i) => renderColorInput(color, i))}
            </List>
            <CancelAndConfirmButton onCancel={onCancel} onSubmit={onSubmit} disabled={disabled} />
            {isLoading && (
                <div className={classes.loaderWrapper}>
                    <CircularProgress />
                </div>
            )}
        </Accordion>
    );
};

export default ColorPaletteAccordionContainer;
