import React, { FC, useState } from 'react';
import ActionButton from '../../Components/ActionButton/ActionButton';
import { Event } from '../../Models/event.model';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import { useSnackbar, VariantType } from 'notistack';
import RefreshEventDialog from '../../Components/Dialogs/RefreshEventDialog/RefreshEventDialog';
import { postRefreshUsers } from '../../Services/http/event.service';
import { t } from 'i18next';

interface Props {
    onCompleteCallback: () => void;
}

const RefreshEventActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const { state } = useEventManagement();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    const sendRefreshEvent = (refreshWindow: number, redirectUrl: string) => {
        const refreshWindowToSend = refreshWindow > 0 ? refreshWindow * 1000 : 1;
        if (selectedEvent) {
            const bodyData = {
                event_code: state.eventCode,
                refresh_delay_window: refreshWindowToSend,
            };
            const body = redirectUrl ? { ...bodyData, url: redirectUrl } : bodyData;
            postRefreshUsers(body)
                .then((res: any) => {
                    onToggle();
                    if (res?.status === 200) {
                        enqueueSnackbar('Event refresh complete', {
                            variant: 'success',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                        });
                    }
                })
                .catch((e: any) => {
                    let errorMessage = 'Refresh Error: event not refreshed';
                    let variant: VariantType = 'error';
                    if (e.message === 'Request failed with status code 400') {
                        errorMessage = 'No sessions found for event';
                        variant = 'warning';
                    }
                    enqueueSnackbar(errorMessage, {
                        variant,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                });
        }
    };

    const onToggle = () => {
        if (!open) {
            const { events, selectedEvents } = state;
            events.forEach((event) => {
                // ...button is only rendered when one event is selected
                if (event.event_code === selectedEvents[0].event_code) {
                    setSelectedEvent(event);
                }
            });
        } else {
            setSelectedEvent(null);
        }
        setErrorMessage('');
        setOpen(!open);
    };

    return (
        <>
            <ActionButton
                onClick={onToggle}
                text={t('domainManagement.refreshDomain')}
                id="domains-refresh-domain"
            />
            {open && (
                <RefreshEventDialog
                    onSubmit={sendRefreshEvent}
                    toggleDialog={onToggle}
                    errorMessage={errorMessage}
                />
            )}
        </>
    );
};

export default RefreshEventActionContainer;
