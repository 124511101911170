import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import { LoginPage } from './Pages/Login/LoginPage';
import { OAuth2Page } from './Pages/Login/OAuth2Page';
import ProtectedRouter from './Components/Auth/ProtectedRouter';
import { UserManagementPage } from './Pages/UserManagementPage/UserManagementPage';
import { EventManagementPage } from './Pages/EventManagement/EventManagementPage';
import { HomePage } from './Pages/HomePage/HomePage';
import { makeStyles, createStyles } from '@mui/styles';
import HeaderNavigationContainer from './Containers/HeaderNavigationContainer/HeaderNavigationContainer';
import { RoomManagementPage } from './Pages/RoomManagementPage/RoomManagementPage';
import { ReportedUserManagementPage } from './Pages/ReportedUserManagementPage/ReportedUserManagementPage';
import useLayoutManagement from './Hooks/LayoutManagement/useLayoutManagement';
import clsx from 'clsx';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import ContextProviders from './Contexts/providers';
import { UseCaseManagementPage } from './Pages/UseCaseManagementPage/UseCaseManagementPage';
import { CreateUseCasePage } from './Pages/CreateUseCasePage/CreateUseCasePage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { I18nProviderWrapper } from './Providers/I18nProvider';
import i18n from './i18n';
import { t } from 'i18next';

export const protectedRoutes = [
    {
        path: '/',
        component: <HomePage />,
        name: 'Home',
        exact: true,
    },
    {
        path: '/users',
        component: <UserManagementPage />,
        name: 'Users',
        exact: true,
    },
    {
        path: '/reality-linking',
        component: <RoomManagementPage />,
        name: t('homePage.linkManagement'),
        exact: true,
    },
    {
        path: '/domains',
        component: <EventManagementPage />,
        name: t('homePage.domainManagement'),
        exact: true,
    },
    {
        path: '/report-user-management',
        component: <ReportedUserManagementPage />,
        name: 'Report User Management',
        exact: true,
    },
    {
        path: '/use-cases-management',
        component: <UseCaseManagementPage />,
        name: 'Use Cases Management',
        exact: true,
    },
    {
        path: '/create-use-case/:useCaseId?',
        component: <CreateUseCasePage />,
        name: 'Create Use Case',
        exact: true,
    },
    {
        path: '/room-management',
        component: <Redirect to="/reality-linking" />,
        exact: true,
    },
    {
        path: '/event-management',
        component: <Redirect to="/domains" />,
        exact: true,
    },
];

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: '#a100ff',
            main: '#7500c0',
            dark: '#460073',
        },
        secondary: {
            light: '#e6e6dc',
            main: '#96968c',
            dark: '#00000',
        },
        info: {
            main: '#0041f0',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        // contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: `"Graphik", "Helvetica", "Arial", sans-serif`,
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainerShift: {
            width: 'calc(100% * (2 / 3))',
            marginRight: 'calc(100% / 3)',
            // transition: theme.transitions.create(['margin', 'width'], {
            //     // easing: theme.transitions.easing.easeOut,
            //     // duration: theme.transitions.duration.enteringScreen,
            // }),
            height: '100%',
        },
    }),
);

function App() {
    const layoutManagement = useLayoutManagement();
    const classes = useStyles();

    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <I18nProviderWrapper i18n={i18n}>
                    <ContextProviders>
                        <Router>
                            <Switch>
                                <Route path="/login" component={LoginPage} />
                                <Route path="/auth/callback/oauth2" component={OAuth2Page} />
                                {protectedRoutes.map((route) => (
                                    <ProtectedRouter
                                        key={self.crypto.getRandomValues(new Uint32Array(1))[0]}
                                        path={route.path}
                                        exact={route.exact}
                                    >
                                        <div
                                            className={clsx({
                                                [classes.pageContainerShift]:
                                                    layoutManagement.state.helpDrawerIsActive,
                                            })}
                                        >
                                            <HeaderNavigationContainer />
                                            {route.component}
                                        </div>
                                    </ProtectedRouter>
                                ))}
                            </Switch>
                        </Router>
                    </ContextProviders>
                </I18nProviderWrapper>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default App;
