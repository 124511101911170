import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import LoginContainer from '../../Containers/AuthenticationContainer/LoginContainer';
import AuthLayout from '../../Containers/AuthenticationContainer/AuthLayout';

type LoginPageProps = {
    title: string;
};

const useStyles = makeStyles(() =>
    createStyles({
        containerWrapper: {
            height: '100vh',
        },
    }),
);

export const LoginPage: FC<LoginPageProps> = () => {
    const classes = useStyles();
    return (
        <AuthLayout>
            <LoginContainer />
        </AuthLayout>
    );
};
