import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, Grid, Typography } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { CardCreation } from '../../Components/CardCreation/CardCreation';
import { InputField } from '../../Components/InputField/InputField';
import { UPDATE_AGENT } from '../../Contexts/useCaseContext';
import useCaseManagement from '../../Hooks/UseCaseManagement/UseCaseManagement';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        agentBlock: {
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(3),
        },
        formFields: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
            flex: 4,
        },
        subCard: {
            backgroundColor: '#2B2B2E',
            borderRadius: '0.25rem',
            borderColor: '#484848',
            border: '0.5px solid',
            padding: theme.spacing(2),
            height: '100%',
            color: theme.palette.text.primary,
            cursor: 'pointer',
            flex: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing(2),
        },
        avatar: {
            maxHeight: '8rem',
            maxWidth: '8rem',
        },
    }),
);

const VisuallyHiddenInput = styled('input')({
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const AgentForm: React.FC = () => {
    const classes = useStyles();
    const { state, dispatch } = useCaseManagement();
    const theme = useTheme();

    const buttonStyle = {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.background.default,
        width: '120px',
        padding: theme.spacing(0.25),
    };

    function handleChangeName(newName: string) {
        updateAgentInfo(newName, state.agent.agentDesc, state.agent.img);
    }

    function handleChangeDescription(newDescription: string) {
        updateAgentInfo(state.agent.agentName, newDescription, state.agent.img);
    }

    function handleChangeImg(newImg: FileList | null) {
        if (newImg) {
            updateAgentInfo(state.agent.agentName, state.agent.agentDesc, newImg[0]);
        }
    }

    function updateAgentInfo(agentName: string, agentDescription: string, agentImg: File | null) {
        dispatch({
            type: UPDATE_AGENT,
            payload: {
                agentName: agentName,
                agentDesc: agentDescription,
                img: agentImg,
            },
        });
    }

    return (
        <CardCreation
            title="Character Information"
            popoverContent="e.g. Julie Sweet, CEO of Accenture, friendly intro"
        >
            <Grid item xs={12}>
                <div className={classes.agentBlock}>
                    <div className={classes.formFields}>
                        <InputField
                            placeHolder="Name:"
                            value={state.agent.agentName}
                            onChange={(v) => handleChangeName(v)}
                            autoComplete="character name"
                        />
                        <InputField
                            placeHolder="Description & Behaviour"
                            value={state.agent.agentDesc}
                            onChange={(v) => handleChangeDescription(v)}
                            autoComplete="character description"
                            multiline
                        />
                    </div>
                    <div className={classes.subCard}>
                        {state.agent.img ? (
                            <img
                                className={classes.avatar}
                                src={URL.createObjectURL(state.agent.img)}
                                alt="avatar"
                            />
                        ) : (
                            <>
                                <Typography variant="body1" fontWeight="fontWeightBold">
                                    Character Image
                                </Typography>
                                <UploadFileIcon fontSize="large" />
                                <Typography variant="body2" align="center">
                                    Drag & Drop to upload
                                </Typography>
                            </>
                        )}
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            sx={buttonStyle}
                        >
                            Browse
                            <VisuallyHiddenInput
                                type="file"
                                onChange={(event) => handleChangeImg(event.target.files)}
                                multiple
                                accept="image/jpeg,image/png"
                            />
                        </Button>
                    </div>
                </div>
            </Grid>
        </CardCreation>
    );
};
