import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC, useEffect, useState } from 'react';
import { Event } from '../../../Models/event.model';
import { useTranslation } from 'react-i18next';

interface Props {
    onSubmit: (event: Event, eventCode?: string) => void;
    toggleDialog: () => void;
    errorMessage: string;
    editEvent: Event | null;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            padding: theme.spacing(1, 3),

            '& .MuiTypography-root': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        formContainer: {
            '& .MuiTextField-root': {
                width: 300,
            },

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: theme.spacing(3),
        },
        closeButton: {
            color: theme.palette.grey[500],
            transform: 'translateX(25%)',
        },
        dialogContent: {
            '& div:not(.MuiSelect-select)': {
                marginBottom: theme.spacing(1),
            },
        },
    }),
);

const EventDialog: FC<Props> = ({ onSubmit, toggleDialog, errorMessage, editEvent }) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    const [name, setName] = useState('');
    const [eventCode, setEventCode] = useState('');
    const [mode, setMode] = useState('');
    const [description, setDescription] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (editEvent) {
            setName(editEvent.name);
            setEventCode(editEvent.event_code);
            setMode(editEvent.mode);
            if (editEvent.description) setDescription(editEvent.description);
        }
    }, [editEvent]);

    const [errors, setErrors] = useState({
        name: '',
        eventCode: '',
        mode: '',
        description: '',
    });

    const handleClose = () => {
        setOpen(false);
        toggleDialog();
    };

    const handleSubmit = async () => {
        if (handleValidation()) {
            onSubmit({ name, event_code: eventCode, mode, description });
        }
    };

    const handleValidation = () => {
        const newErrors = { ...errors };
        let formIsValid = true;

        // ...name
        if (name === '') {
            formIsValid = false;
            newErrors.name = 'Cannot be empty';
        } else if (name.length > 100) {
            formIsValid = false;
            newErrors.name = 'Cannot be longer than 100 characters';
        } else {
            newErrors.name = '';
        }

        // ...eventCode
        if (eventCode === '') {
            formIsValid = false;
            newErrors.eventCode = 'Cannot be empty';
        } else if (eventCode.length > 30) {
            formIsValid = false;
            newErrors.name = 'Cannot be longer than 30 characters';
        } else {
            newErrors.eventCode = '';
        }

        // ...mode
        if (mode === '') {
            formIsValid = false;
            newErrors.mode = 'Cannot be empty';
        } else if (mode.length > 100) {
            formIsValid = false;
            newErrors.mode = 'Cannot be longer than 100 characters';
        } else {
            newErrors.mode = '';
        }

        // ...description
        if (description.length > 1000) {
            formIsValid = false;
            newErrors.description = 'Cannot be longer than 1000 characters';
        } else {
            newErrors.description = '';
        }

        setErrors(newErrors);

        return formIsValid;
    };

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle className={classes.dialogTitle} id="customized-dialog-title">
                    <Typography>
                        {editEvent ? 'Edit' : 'Create'} {t('domainManagement.domainDialog')}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                        id="domains-edit-domain-dialog-close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.formContainer} noValidate autoComplete="off">
                        <TextField
                            required={!editEvent}
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={errors.name !== ''}
                            helperText={errors.name !== '' ? errors.name : ''}
                        />
                        {!editEvent && (
                            <TextField
                                required={!editEvent}
                                label={t('userManagement.domainHeader')}
                                value={eventCode}
                                onChange={(e) => setEventCode(e.target.value)}
                                error={errors.eventCode !== ''}
                                helperText={errors.eventCode !== '' ? errors.eventCode : ''}
                            />
                        )}
                        <TextField
                            required={!editEvent}
                            label="Mode"
                            value={mode}
                            onChange={(e) => setMode(e.target.value)}
                            error={errors.mode !== ''}
                            helperText={errors.mode !== '' ? errors.mode : ''}
                        />
                        <TextField
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            error={errors.description !== ''}
                            helperText={errors.description !== '' ? errors.description : ''}
                        />
                        {errorMessage !== '' ? (
                            <FormHelperText error>{errorMessage}</FormHelperText>
                        ) : undefined}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        id="domains-edit-domain-dialog-cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        id="domains-edit-domain-dialog-update"
                    >
                        {editEvent ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EventDialog;
