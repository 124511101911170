import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpeningHours from '../../Components/OpeningHours/OpeningHours';
import { getOpeningHours, postOpeningHours } from '../../Services/http/event.service';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import { useSnackbar } from 'notistack';
import { DatePickerType } from '../../Models/date.model';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';

interface OpenHour {
    from_timestamp: string;
    to_timestamp: string;
    isDeleted?: boolean;
    isStartEdited?: boolean;
    isEndEdited?: boolean;
}

const NoBorderTableCell = withStyles({
    root: {
        borderBottom: 'none',
        width: '33%',
    },
})(TableCell);

const useStyles = makeStyles(() =>
    createStyles({
        accordion: {
            display: 'flex',
            flexDirection: 'column',
        },
        tableHead: {
            background: 'gray',
        },
        tableHeadCell: {
            color: 'white',
        },
        tableRow: {
            display: 'flex',
            alignItems: 'center',
        },
        table: {
            justifySelf: 'center',
            marginLeft: 20,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        buttonWrapper: {
            marginBottom: '1%',
            marginRight: '2%',
        },
        buttons: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        button: {
            width: '50%',
            margin: '2%',
            flex: 1,
        },
        text: {
            flex: 2,
            marginLeft: -15,
        },
    }),
);

const OpeningHoursAccordionContainer = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { state } = useEventManagement();
    const [openHours, setOpenHours] = React.useState<OpenHour[]>([]);
    const [newOpenHours, setNewOpenHours] = useState<OpenHour[]>([]);
    const [disabled, setDisabled] = useState(false);
    const [key, setKey] = useState(self.crypto.getRandomValues(new Uint32Array(1))[0]);

    const getOpenHours = useCallback(async () => {
        await getOpeningHours(state.eventCode).then((resp) => {
            const response = [...resp.data.event_open_hours];
            response.forEach((time: any, i: number) => {
                if (time.from_timestamp === null || time.to_timestamp === null) {
                    response.splice(i, 1);
                }
            });
            setOpenHours(response);
        });
        setNewOpenHours([]);
        setDisabled(true);
        setKey(self.crypto.getRandomValues(new Uint32Array(1))[0]);
    }, [state.eventCode]);

    useEffect(() => {
        getOpenHours();
    }, [getOpenHours]);

    const checkDisabled = () => {
        let bool = false;
        openHours.forEach((hours) => {
            if (hours.from_timestamp > hours.to_timestamp && !hours.isDeleted) bool = true;
        });
        let boolNew = false;
        newOpenHours.forEach((hours) => {
            if (hours.from_timestamp > hours.to_timestamp) boolNew = true;
        });
        setDisabled(bool || boolNew);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const onDelete = (index: number) => {
        const modifiedOpenHours: OpenHour[] = [...openHours].map((openHour, i) => {
            if (i === index) {
                openHour.isDeleted = true;
            }

            return openHour;
        });

        setOpenHours(modifiedOpenHours);
        checkDisabled();
    };

    function onEdit(index: number, newDate: Date, type: DatePickerType, isAdd?: boolean) {
        const openHoursArray: OpenHour[] = isAdd ? [...newOpenHours] : [...openHours];
        const modifiedOpenHours: OpenHour[] = openHoursArray.map((openHour, i) => {
            if (i === index) {
                if (openHour.isDeleted) delete openHour.isDeleted;

                switch (type) {
                    case DatePickerType.Start: {
                        openHour.from_timestamp = newDate.toISOString();
                        if (!isAdd) openHour.isStartEdited = true;
                        break;
                    }
                    case DatePickerType.End: {
                        openHour.to_timestamp = newDate.toISOString();
                        if (!isAdd) openHour.isEndEdited = true;
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
            return openHour;
        });
        setOpenHours(modifiedOpenHours);
        checkDisabled();
    }

    const onAdd = () => {
        setNewOpenHours([
            ...newOpenHours,
            {
                from_timestamp: new Date().toISOString(),
                to_timestamp: new Date().toISOString(),
            },
        ]);
        checkDisabled();
    };

    const handleDelete = (isNew?: boolean, index?: number) => {
        if (isNew && index !== undefined) {
            const frontPart = newOpenHours;
            frontPart.splice(index, 1);
            setNewOpenHours([...frontPart]);
        }
    };

    const onCancel = async () => {
        getOpenHours();
    };

    const onConfirm = async () => {
        const existingDate = openHours.filter((o) => !o.isDeleted);
        existingDate.forEach((openHour) => {
            if (openHour.isStartEdited) delete openHour.isStartEdited;
            if (openHour.isEndEdited) delete openHour.isEndEdited;
        });

        const payload = [...existingDate];

        if (newOpenHours.length) {
            payload.push(...newOpenHours);
        }

        await postOpeningHours(state.eventCode, payload)
            .then((response) => {
                enqueueSnackbar(response.data.message, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            });
        getOpenHours();
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    <strong>Opening Hours</strong>
                </Typography>
            </AccordionSummary>
            <TableContainer className={classes.table}>
                <Table aria-label="simple table">
                    <TableBody key={key}>
                        {openHours.map((timing, index) => {
                            if (timing.to_timestamp && timing.from_timestamp) {
                                return (
                                    <OpeningHours
                                        startTime={new Date(timing.from_timestamp)}
                                        endTime={new Date(timing.to_timestamp)}
                                        onDelete={() => onDelete(index)}
                                        onEdit={(newDate, type) => onEdit(index, newDate, type)}
                                        isStartEdited={timing.isStartEdited}
                                        isEndEdited={timing.isEndEdited}
                                        key={`opening-${index}`}
                                    />
                                );
                            }
                            return null;
                        })}
                        {newOpenHours.map((newOpenHour, index) => {
                            return (
                                <OpeningHours
                                    startTime={new Date(newOpenHour.from_timestamp)}
                                    endTime={new Date(newOpenHour.to_timestamp)}
                                    onDelete={() => handleDelete(true, index)}
                                    onEdit={(newDate, type) => onEdit(index, newDate, type, true)}
                                    isNew
                                    key={`new-${index}`}
                                />
                            );
                        })}
                        <TableRow>
                            <NoBorderTableCell>
                                {!openHours.length && !newOpenHours.length && (
                                    <span className={classes.text}>
                                        {' '}
                                        Enter a new pair of opening hours{' '}
                                    </span>
                                )}
                            </NoBorderTableCell>
                            <NoBorderTableCell />
                            <NoBorderTableCell>
                                {' '}
                                <Button
                                    variant="contained"
                                    color="default"
                                    onClick={onAdd}
                                    className={classes.button}
                                >
                                    Add
                                </Button>{' '}
                            </NoBorderTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <CancelAndConfirmButton
                onCancel={onCancel}
                onSubmit={onConfirm}
                disabled={false}
                disabledSubmit={(!openHours.length && !newOpenHours.length) || disabled}
            />
        </Accordion>
    );
};

export default OpeningHoursAccordionContainer;
