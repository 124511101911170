import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

interface Props {
    placeHolder?: string;
    value?: string;
    errorMsg?: string;
    autoComplete?: string;
    disabled?: boolean;
    onChange?: (text: string) => void;
    clickEnter?: Function;
    color?: 'primary' | 'secondary';
    size?: 'large' | 'small';
    multiline?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputField: {
            width: '100%',
            boxSizing: 'border-box',
            alignItems: 'flex-start',
            gap: '4px',
            flex: '1 0 0',
            alignSelf: 'stretch',
            borderRadius: ' 0.25rem',
            border: '0.5px solid',
            borderColor: '#484848',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 'bold',
        },
        primary: {
            color: '#E0E0E0',
            backgroundColor: '#2B2B2E',
        },
        secondary: {
            color: theme.palette.background.default,
            backgroundColor: theme.palette.action.active,
        },
        large: {
            padding: theme.spacing(2),
        },
        small: {
            padding: theme.spacing(1),
        },
        multiLine: {
            height: '150px',
            resize: 'none',
        },
    }),
);

export const InputField: React.FC<Props> = ({
    placeHolder,
    value,
    errorMsg,
    autoComplete,
    disabled,
    onChange,
    clickEnter,
    color,
    size,
    multiline,
}) => {
    const classes = useStyles();
    const onChangeInput = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        if (onChange) onChange(e.target.value);
    };

    const onClickEnter = () => {
        if (clickEnter) clickEnter();
    };

    const getIsPrimary = (): string => {
        if (color === 'secondary') return classes.secondary;
        return classes.primary;
    };

    const getIsLarge = (): string => {
        if (size === 'small') return classes.small;
        return classes.large;
    };

    const getIsMultiline = (): string => {
        if (multiline) return classes.multiLine;
        return '';
    };

    return (
        <div>
            {multiline ? (
                <textarea
                    id="search"
                    name="search"
                    title="search"
                    placeholder={placeHolder}
                    value={value ?? ''}
                    onChange={(event) => onChangeInput(event)}
                    onKeyDown={onClickEnter}
                    disabled={disabled}
                    className={`${classes.inputField} ${getIsPrimary()}
                            ${getIsLarge()} ${getIsMultiline()}`}
                    autoComplete={autoComplete}
                ></textarea>
            ) : (
                <input
                    type="text"
                    id="search"
                    name="search"
                    className={`${classes.inputField} ${getIsPrimary()}
                            ${getIsLarge()} ${getIsMultiline()}`}
                    title="search"
                    placeholder={placeHolder}
                    value={value ?? ''}
                    onChange={(event) => onChangeInput(event)}
                    onKeyDown={onClickEnter}
                    disabled={disabled}
                    autoComplete={autoComplete}
                />
            )}
            {errorMsg && <label> {errorMsg}</label>}
        </div>
    );
};
