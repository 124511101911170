import React, { FC, ReactNode, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import userReportedUserManagement from '../../Hooks/ReportedUserManagement/userReportedUserManagement';
import { ReportedUser } from '../../Models/report.model';
import { getReportedUsers } from '../../Services/http/user.service';
import {
    UPDATE_REPORTED_USER_LIST,
    UPDATE_SELECTED_REPORTED_USERS,
} from '../../Contexts/reportedUserManagementContext';
import useTableSearch from '../../Hooks/TableSearch/useTableSearch';
import TableSearch from '../../Components/TableSearch/TableSearch';
import ReportedUserManagementTable from '../../Components/ReportedUserManagementTable/ReportedUserManagementTable';
import { ReportedUserCell } from '../../Models/table-cells.model';
import DeactivateUserActionContainer from '../../Containers/DeactivateUserActionContainer/DeactivateUserActionContainer';
const searchFields = [
    'status',
    'event_code',
    'reported_user_username',
    'reported_by_user_username',
];

const ReportedUserManagementTableContainer: FC = () => {
    const { state, dispatch } = userReportedUserManagement();
    const [isLoading, setIsLoading] = useState(true);
    const [reportedUsers, setReportedUsers] = useState([] as ReportedUser[]);
    const [filteredReportedUsers, setFilteredReportedUsers] = useState([] as ReportedUser[]);
    const [selectedReportedUsers, setSelectedReportedUsers] = useState<ReportedUser[]>([]);
    const [page, setPage] = useState(0);

    const [searchText, setSearchText, onSearch] = useTableSearch(setSelectedReportedUsers);

    const fetchReportedUsers = () => {
        setIsLoading(true);
        getReportedUsers()
            .then((res: any) => {
                setReportedUsers(res.data);
                setFilteredReportedUsers(res.data);
                dispatch({ type: UPDATE_REPORTED_USER_LIST, payload: { reportedUsers: res.data } });
                setIsLoading(false);
            })
            .catch((e) => {
                console.error('an error occured:', e.message);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        fetchReportedUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, page]);

    useEffect(() => {
        setPage(0);
        getDisplayRow(searchText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const getDisplayRow = (text: any) => {
        setFilteredReportedUsers([]);
        const _filteredUsers = [];
        for (let i = 0; i < reportedUsers.length; i++) {
            for (const [key, value] of Object.entries(reportedUsers[i])) {
                if (
                    typeof value === 'string' &&
                    value.indexOf(text) > -1 &&
                    searchFields.indexOf(key) > -1
                ) {
                    _filteredUsers.push(reportedUsers[i]);
                    break;
                }
            }
        }
        setFilteredReportedUsers(_filteredUsers);
    };

    const handleSetSelectedEvents = (reportedUsers: ReportedUserCell[]) => {
        setSelectedReportedUsers(reportedUsers);
        dispatch({
            type: UPDATE_SELECTED_REPORTED_USERS,
            payload: { selectedReportedUsers: reportedUsers },
        });
    };

    const handleActionComplete = () => {
        fetchReportedUsers();
        setSelectedReportedUsers([]);
        setSearchText('');
    };

    const actionItems: ReactNode[] = [
        <DeactivateUserActionContainer
            key="take action"
            onCompleteCallback={handleActionComplete}
        />,
    ];

    return (
        <Paper elevation={0}>
            <Box height="100%" width="100%" marginLeft="auto" marginRight="auto">
                <TableSearch
                    onSearch={onSearch}
                    setSearchText={setSearchText}
                    searchText={searchText}
                />
                <ReportedUserManagementTable
                    isLoading={isLoading}
                    page={page}
                    setPage={setPage}
                    selectedItems={selectedReportedUsers}
                    setSelectedItems={handleSetSelectedEvents}
                    reportedUsers={filteredReportedUsers}
                    actionItems={actionItems}
                />
            </Box>
        </Paper>
    );
};

export default ReportedUserManagementTableContainer;
