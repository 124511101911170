import axios from 'axios';
import { LlmOptions, NewUseCase } from '../../Models/useCase.model';
import Config from '../../config.json';

const apiUrl = Config.VSF_K8S_API_URL;
const apiVersion = Config.VSF_K8S_API_VERSION;

const servicePrefix = `${apiUrl}/genai-agent${apiVersion}`;

export function createAgent(agent: NewUseCase): Promise<any> {
    const payload = agent;
    return axios.post(`${servicePrefix}/agent`, payload);
}

export function editAgent(editedAgentId: number, editedAgent: NewUseCase): Promise<any> {
    const payload = editedAgent;
    return axios.patch(`${servicePrefix}/agent/${editedAgentId}`, payload);
}

export function deleteAgent(deletedAgentId: number): Promise<any> {
    return axios.delete(`${servicePrefix}/agent/${deletedAgentId}`);
}

export function getAgent(agentId: number): Promise<any> {
    return axios.get(`${servicePrefix}/agent/${agentId}`);
}

export function getAllAgent(): Promise<any> {
    return axios.get(`${servicePrefix}/agents`);
}

export function getLlmOptions(): Promise<{ data: LlmOptions }> {
    return axios.get(`${servicePrefix}/llm/options`);
}

export function getAgentImage(agentId: number): Promise<any> {
    return axios.get(`${servicePrefix}/agent/${agentId}/image`);
}

export function uploadAgentImage(agentId: number, img: File): Promise<any> {
    const formData = new FormData();
    formData.append('image', img);
    return axios.put(`${servicePrefix}/agent/${agentId}/image`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}
