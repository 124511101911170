export {};
import axios from 'axios';
import Config from '../../config.json';
import { Silo, SiloCreated, UseCaseFile } from '../../Models/useCase.model';

const apiUrl = Config.VSF_K8S_API_URL;
const apiVersion = Config.VSF_K8S_API_VERSION;

const servicePrefix = `${apiUrl}/genai-agent${apiVersion}`;

export function createSilo(agentId: any, silo: SiloCreated): Promise<any> {
    const payload = silo;
    return axios.post(`${servicePrefix}/agent/${agentId}/silo`, payload);
}

export function updateSilo(siloId: number, silo: SiloCreated): Promise<any> {
    const payload = silo;
    return axios.patch(`${servicePrefix}/silo/${siloId}`, payload);
}

export function deleteSilo(siloId: number): Promise<any> {
    return axios.delete(`${servicePrefix}/silo/${siloId}`);
}

export function uploadFileInSilo(siloId: number, siloFile: UseCaseFile): Promise<any> {
    const formData = new FormData();
    formData.append('file', siloFile.file);
    formData.append('description', siloFile.description ?? '');
    return axios.post(`${servicePrefix}/silo/${siloId}/files`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function updateFileInSilo(
    siloId: number,
    fileId: number,
    siloFile: UseCaseFile,
): Promise<any> {
    const formData = new FormData();
    formData.append('file', siloFile.file);
    formData.append('description', siloFile.description ?? '');
    return axios.patch(`${servicePrefix}/silo/${siloId}/file/${fileId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function deleteFileInSilo(siloId: number, fileId: number): Promise<any> {
    return axios.delete(`${servicePrefix}/silo/${siloId}/file/${fileId}`);
}
