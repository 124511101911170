import React, { FC, useContext, useEffect, useState } from 'react';
import * as authService from '../../Services/http/auth.service';
import { AuthContext, UPDATE_CURRENT_USER } from '../../Contexts/authContext';
import { useHistory, useLocation } from 'react-router-dom';
import {
    setLocalStorageItemValue,
    getLocalStorageItemValue,
} from '../../Services/local-storage.service';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& div[class*="MuiGrid-spacing"]': {
                margin: 0,
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    flexWrap: 'nowrap',
                },
            },
            '& div[class*="MuiGrid-item"]': {
                padding: '0 24px',
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            height: '100vh',
        },
    }),
);

function useQuery() {
    return new URLSearchParams(decodeURI(useLocation().search));
}

const OAuth2Container: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const location: any = useLocation();
    const query = useQuery();

    const { from } = location.state || { from: { pathname: '/' } };
    const { state, dispatch } = useContext(AuthContext);

    //
    const eventCodeStorage = getLocalStorageItemValue('eventCode') || '';
    const callbackCode = query.get('code') || '';
    const activeProvider = window.location.pathname.substring(
        window.location.pathname.lastIndexOf('/') + 1,
    );

    const oAuth2SignIn = () => {
        authService
            .postOauth2(
                authService.FrontendTypes.ADMIN,
                activeProvider,
                eventCodeStorage,
                callbackCode,
            )
            .then((res: any) => {
                if (res.data && res.data.user_data && res.data.user_data.id) {
                    setLocalStorageItemValue('currentUser', JSON.stringify(res.data.user_data));
                    dispatch({ type: UPDATE_CURRENT_USER, payload: res.data.user_data });

                    const urlParam = new URLSearchParams(location.search).get('redirectTo');
                    if (urlParam) {
                        window.location.replace(`${urlParam}?auth=${res.data.user_data.jwt_token}`);
                    } else {
                        history.replace(from);
                    }
                }
            })
            .catch((err: any) => {
                setLocalStorageItemValue('oauthLoginError', err.message);
                window.location.replace('/login');
            })
            .finally(() => {
                setLocalStorageItemValue('eventCode', '');
            });
    };

    useEffect(() => {
        oAuth2SignIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            <div>
                <CircularProgress variant="determinate" size="md" />
            </div>
        </div>
    );
};

export default OAuth2Container;
