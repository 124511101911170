import { Breadcrumbs } from '@mui/material';
import { Container } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { Link } from '@mui/material';
import { List } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { RoomChatManagementTableContainer } from '../../Containers/RoomChatManagementTableContainer/RoomChatManagementTableContainer';
import { getLocalStorageItemValue } from '../../Services/local-storage.service';
import { UPDATE_SELECTED_ROOM } from '../../Contexts/roomManagementContext';
import useRoomManagement from '../../Hooks/RoomManagement/useRoomManagement';
import { Room } from '../../Models/socket.model';
import { EventCell } from '../../Models/table-cells.model';
import { fetchAllEvents } from '../../Services/http/event.service';
import { RoomsTable } from '../../Components/RoomManagement/RoomsTable';
import { getRooms } from '../../Services/http/content.service';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    breadcrumb: {
        marginTop: theme.spacing(6),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        '& .MuiInputLabel-root': {
            color: '#ffffff',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.primary.main,
        },
    },
    dropdown: {
        color: '#ffffff',
    },
}));

export const RoomManagementPage: FC = () => {
    const { state, dispatch } = useRoomManagement();
    const classes = useStyles();
    const localStorageValue = getLocalStorageItemValue('currentUser');
    const [eventCode, setEventCode] = useState<string>('');
    const [roomsInEvent, setRoomsInEvent] = useState<Room[] | []>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [page, setPage] = useState(1);
    // const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selectedRoom, setSelectedRoom] = useState<Room | null>();
    const [allEvents, setAllEvents] = useState<EventCell[]>([]);
    const [disabled, setIsDisabled] = useState(false); //set true if user is domain-admin
    const { t } = useTranslation();

    useEffect(() => {
        getEventName();
        getEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {}, [allEvents]);

    useEffect(() => {
        const { selectedRoom: room } = state;
        setSelectedRoom(room);
    }, [state]);

    const onRoomClick = (room: Room) => {
        dispatch({
            type: UPDATE_SELECTED_ROOM,
            payload: room,
        });
    };

    const getEvents = () => {
        if (!disabled) {
            fetchAllEvents().then((res: any) => {
                const events = res?.data?.events;
                setAllEvents(events);
            });
        }
    };

    const refetchRooms = () => {
        getEventRooms(eventCode);
    };

    const getEventRooms = (eventCode?: string) => {
        setIsLoading(true);
        getRooms(eventCode)
            .then((res) => {
                const events = res.data.event_rooms.filter(
                    (event: any) => event.event === eventCode,
                );
                events[0]?.rooms?.length ? setRoomsInEvent(events[0].rooms) : setRoomsInEvent([]);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('An error occurred:', error.message);
                setIsLoading(false);
            });
    };

    const handleChange = (event: any) => {
        setEventCode(event.target.value);
        getEventRooms(event.target.value);
    };

    const getEventName = () => {
        if (localStorageValue) {
            if (
                JSON.parse(localStorageValue).roles.filter((role: any) => role.role === 'admin')
                    .length === 0
            )
                setIsDisabled(true);
            setEventCode(JSON.parse(localStorageValue).event_code);
        }
        getEventRooms(JSON.parse(localStorageValue).event_code);
    };

    return (
        <Container maxWidth={'xl'}>
            {!selectedRoom && (
                <>
                    <div className={classes.breadcrumb}>
                        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                            <Link color="inherit" href="/" id="reality-linking-breadcrumb-home">
                                Home
                            </Link>
                            <Link
                                color="textPrimary"
                                href="/room-management"
                                aria-current="page"
                                id="reality-linking-breadcrumb-reality-linking"
                            >
                                {t('homePage.linkManagement')}
                            </Link>
                        </Breadcrumbs>
                    </div>
                    <div>
                        <h1>{t('linkManagement.title')}</h1>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                {t('userManagement.domainHeader')}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={eventCode ? eventCode : ''}
                                onChange={handleChange}
                                disabled={disabled}
                                className={classes.dropdown}
                            >
                                {allEvents.map((event) => {
                                    return (
                                        <MenuItem value={event.event_code} key={event.event_code}>
                                            {event.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <List>
                        <RoomsTable
                            rooms={roomsInEvent}
                            openChat={onRoomClick}
                            // page={page}
                            // setPage={setPage}
                            // rowsPerPage={rowsPerPage}
                            // setRowsPerPage={setRowsPerPage}
                            isLoading={isLoading}
                            refetchRooms={refetchRooms}
                            eventCode={eventCode}
                        />
                    </List>
                </>
            )}
            {selectedRoom && <RoomChatManagementTableContainer eventCode={eventCode} />}
        </Container>
    );
};
