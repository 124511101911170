import { IconButton } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { DELETE_FILE, EDIT_FILE, UPDATE_KNOWLEDGE } from '../../Contexts/useCaseContext';
import useCaseManagement from '../../Hooks/UseCaseManagement/UseCaseManagement';
import { FileTool, Tool } from '../../Models/useCase.model';
import { cutString } from '../../Utils/formatString';
import { InputField } from '../InputField/InputField';

interface ToolFormProps {
    toolIndex: number;
    goBack: () => void;
}

const VisuallyHiddenInput = styled('input')({
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const maxChar: number = 50;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            display: 'flex',
            flexDirection: 'row',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
        },
        topMenu: {
            gap: theme.spacing(2),
        },
        fileScroll: {
            height: '9rem',
            gap: theme.spacing(1),
            overflowX: 'scroll',
            overflowY: 'hidden',
            width: '100%',
        },
        fileBox: {
            borderRadius: '0.25rem',
            border: '0.5px solid #484848',
            background: '#2B2B2E',
            display: 'flex',
            width: '8.75rem',
            height: '6rem',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.625rem',
            wordWrap: 'break-word',
        },
        saveButton: {
            left: '75%',
        },
        subCard: {
            backgroundColor: '#2B2B2E',
            borderRadius: '0.25rem',
            borderColor: '#484848',
            border: '0.5px solid',
            padding: theme.spacing(2),
            height: '100%',
            color: theme.palette.text.primary,
            cursor: 'pointer',
            flex: 3,
            alignItems: 'center',
            gap: theme.spacing(2),
        },
        surface: {
            width: '11.5625rem;',
            border: '0.5px solid #484848',
            backgroundColor: '#1E1E1E',
            borderRadius: theme.spacing(1),
        },
        header: {
            padding: '0.5rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
            backgroundColor: '#2B2B2E',
            height: '30px',
            borderRadius: theme.spacing(1, 1, 0, 0),
        },
        overSizeTopic: {
            borderColor: theme.palette.error.main,
        },
        content: {
            padding: theme.spacing(1, 2),
        },
    }),
);

export const ToolForm: React.FC<ToolFormProps> = ({ toolIndex, goBack }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [description, setDescription] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { state, dispatch } = useCaseManagement();

    const buttonStyle = {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.background.default,
        width: '120px',
        padding: theme.spacing(0.25),
    };

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    }

    async function addFileToTool() {
        if (selectedFile !== null) {
            console.log(selectedFile);
            const newFile: FileTool = {
                id: null,
                name: selectedFile?.name ?? '',
                description: description,
                size_mb: (selectedFile?.size ?? 0) / 1000,
                file: selectedFile,
            };
            const tempToolsList: Array<Tool> = state.knowledge.tools;
            tempToolsList[toolIndex].files.push(newFile);
            tempToolsList[toolIndex].used_size_mb += newFile.size_mb;
            dispatch({
                type: UPDATE_KNOWLEDGE,
                payload: {
                    tools: tempToolsList,
                },
            });
            setSelectedFile(null);
            setDescription('');
        }
    }

    function convertMbToGb(mb: number): number {
        const gb = mb * 0.001;
        return Math.round(gb * 100) / 100;
    }

    function onDelete(toolIndex: number, fileIndex: number) {
        dispatch({
            type: DELETE_FILE,
            payload: {
                toolIndex: toolIndex,
                fileIndex: fileIndex,
            },
        });
    }

    function openEdit(file: FileTool) {
        setDescription(file.description);
        if (file.file) {
            setSelectedFile(file.file);
        }
    }

    function onEdit(toolIndex: number, fileIndex: number, editedFile: FileTool) {
        dispatch({
            type: EDIT_FILE,
            payload: {
                toolIndex: toolIndex,
                fileIndex: fileIndex,
                file: editedFile,
            },
        });
    }

    return (
        <Grid container direction="column" gap={2}>
            <Grid item className={`${classes.topMenu} ${classes.row}`}>
                <Button
                    variant="contained"
                    onClick={goBack}
                    startIcon={<ArrowBackIcon />}
                    sx={buttonStyle}
                >
                    Back
                </Button>
                <Typography>{state.knowledge.tools[toolIndex].name}</Typography>
            </Grid>
            <Grid item>
                <div className={`${classes.fileScroll} ${classes.row}`}>
                    {state.knowledge.tools[toolIndex].files.length === 0 ? (
                        <div className={classes.fileBox}>
                            <Typography variant="caption" align="justify" sx={{ maxWidth: '8rem' }}>
                                empty
                            </Typography>
                        </div>
                    ) : (
                        state.knowledge.tools[toolIndex].files.map(
                            (file: FileTool, fileIndex: number) => (
                                <div
                                    className={`${classes.surface} ${classes.column}`}
                                    key={file.id}
                                >
                                    <div className={`${classes.header} ${classes.row}`}>
                                        <IconButton
                                            onClick={() => onDelete(toolIndex, fileIndex)}
                                            color="inherit"
                                            size="small"
                                        >
                                            <DeleteOutlineOutlinedIcon fontSize="small" />
                                        </IconButton>
                                        {file.file && (
                                            <Typography variant="caption">{`${convertMbToGb(
                                                file.file.size,
                                            )}GB`}</Typography>
                                        )}
                                        <IconButton
                                            onClick={() => openEdit(file)}
                                            color="inherit"
                                            size="small"
                                        >
                                            <ModeEditOutlineOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                    <div className={classes.content}>
                                        <Typography variant="body1">{file.name}</Typography>
                                        {file.description && (
                                            <Typography variant="caption">
                                                {cutString(file.description, maxChar)}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            ),
                        )
                    )}
                </div>
            </Grid>
            <Divider />
            <Grid item container gap={1}>
                <Grid item xs={7}>
                    <InputField
                        placeHolder="File Summary:"
                        value={description}
                        onChange={(v) => setDescription(v)}
                        autoComplete="file description"
                        multiline
                        size="large"
                    />
                </Grid>
                <Grid item xs={4}>
                    <div className={`${classes.subCard} ${classes.column}`}>
                        {selectedFile && (
                            <Typography variant="caption" align="center">
                                {cutString(selectedFile?.name, maxChar)}
                            </Typography>
                        )}
                        <UploadFileIcon fontSize="large" />
                        <Typography variant="body2" align="center">
                            Drag & Drop your files here
                        </Typography>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            sx={buttonStyle}
                        >
                            Browse
                            <VisuallyHiddenInput
                                type="file"
                                onChange={handleFileChange}
                                multiple
                                accept="application/pdf,application/txt"
                            />
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    onClick={addFileToTool}
                    sx={buttonStyle}
                    className={classes.saveButton}
                >
                    Add file
                </Button>
            </Grid>
        </Grid>
    );
};
