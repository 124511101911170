import React, { FC, useState } from 'react';
import DownloadUserDialog from '../../Components/Dialogs/DownloadUserDialog/DownloadUserDialog';
import { downloadUsers } from '../../Services/http/user.service';
import ActionButton from '../../Components/ActionButton/ActionButton';
import { useSnackbar } from 'notistack';
import { getLocalStorageItemValue } from '../../Services/local-storage.service';
interface Props {
    onCompleteCallback: () => void;
}
const DownloadUserListActionActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const toggleDialog = () => {
        setIsDialogOpen(!isDialogOpen);
    };

    const handleDownload = async () => {
        await downloadUsers()
            .then((response) => {
                //download zip file func goes here
                onCompleteCallback();
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

                const link = document.createElement('a');

                link.href = downloadUrl;

                link.setAttribute('download', 'UserData.zip');

                document.body.appendChild(link);
                link.click();

                link.remove();
                enqueueSnackbar('User list is being downloaded as a zip file', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
                setIsDialogOpen(false);
            })
            .catch((e) => {
                enqueueSnackbar(e.message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            });
    };

    return (
        <>
            <ActionButton
                text={'Download User List'}
                icon={null}
                onClick={toggleDialog}
                id="users-download-user-list"
            />
            <DownloadUserDialog
                isDialogOpen={isDialogOpen}
                handleDownload={handleDownload}
                onClose={toggleDialog}
            />
        </>
    );
};

export default DownloadUserListActionActionContainer;
