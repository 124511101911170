import React, { FC, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';

interface Props {
    locale?: string;
    i18n: any;
    children: any;
}

export const I18nProviderWrapper: FC<Props> = ({ children, i18n, locale }) => {
    useEffect(() => {
        if (locale) {
            i18n.changeLanguage(locale);
        }
    }, [i18n, locale]);
    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
