import React, { FC, useState } from 'react';
import ActionConfirmDialog from '../../Components/Dialogs/ActionConfirmDialog/ActionConfirmDialog';
import ActionButton from '../../Components/ActionButton/ActionButton';
import PeopleAddDisabled from '@material-ui/icons/PersonAddDisabled';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import { anonymizeUser } from '../../Services/http/user.service';
import CompleteDialog from '../../Components/Dialogs/ActionCompleteDialog/ActionCompleteDialog';

interface Props {
    onCompleteCallback: () => void;
}

const AnonymizeUserActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const [open, setOpen] = useState(false);
    const { state } = useUserManagement();
    const [successMessages, setSuccessMessages] = useState([] as string[]);
    const [errorMessages, setErrorMessages] = useState([] as string[]);
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);

    const handleConfirmClick = async () => {
        setSuccessMessages([]);
        setErrorMessages([]);

        const successResponses: any[] = [];
        const errResponses: any[] = [];

        await Promise.all(
            state.selectedUsers.map(async (selectedUser) => {
                await anonymizeUser(selectedUser.id)
                    .then((msg: any) => {
                        successResponses.push(`${selectedUser.username} ${msg?.data?.message}`);
                    })
                    .catch((err: any) =>
                        errResponses.push(`${selectedUser.username} ${err?.data?.message}`),
                    );
            }),
        );

        setOpen(false);
        setSuccessMessages(successResponses);
        setErrorMessages(errResponses);
        toggleCompleteDialog();
    };

    const toggleOpen = () => {
        setOpen(!open);
    };

    const toggleCompleteDialog = () => {
        setIsCompleteDialogOpen(!isCompleteDialogOpen);
    };

    const handleCloseCompleteDialogClick = () => {
        if (onCompleteCallback) {
            onCompleteCallback();
        }
        toggleCompleteDialog();
    };

    return (
        <>
            <ActionButton
                onClick={toggleOpen}
                text={'Anonymize'}
                icon={<PeopleAddDisabled />}
                id="selected-user-anonymize"
            />
            <ActionConfirmDialog
                open={open}
                onClose={toggleOpen}
                onConfirm={handleConfirmClick}
                title={'Anonymize User'}
                content={'The selected users will be anonymized, are you sure?'}
            />
            <CompleteDialog
                title="Anonymize User"
                successMessages={successMessages}
                errorMessages={errorMessages}
                open={isCompleteDialogOpen}
                onClose={handleCloseCompleteDialogClick}
            />
        </>
    );
};

export default AnonymizeUserActionContainer;
