import React, { createContext, Dispatch, Reducer, useReducer } from 'react';
import { FileTool, Silo, Tool } from '../Models/useCase.model';

export const UPDATE_USE_CASE_ID = 'UPDATE_USE_CASE_ID';
export const UPDATE_GEN_AI = 'UPDATE_GEN_AI';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const UPDATE_AI_MODEL = 'UPDATE_AI_MODEL';
export const UPDATE_KNOWLEDGE = 'UPDATE_KNOWLEDGE';
export const DELETE_FILE = 'DELETE_FILE';
export const EDIT_FILE = 'EDIT_FILE';

interface UpdateGenAIAction {
    type: typeof UPDATE_GEN_AI;
    payload: {
        name: string;
        description: string;
        domain: string;
        maxBudget: number | null;
        isActive: boolean;
    };
}

interface UpdateAgentAction {
    type: typeof UPDATE_AGENT;
    payload: {
        agentName: string;
        agentDesc: string;
        img: File | null;
    };
}

interface UpdateAiModelAction {
    type: typeof UPDATE_AI_MODEL;
    payload: {
        provider_id: number | null;
        model_id: number | null;
        region_id: number | null;
    };
}

interface UpdateKnowledgeAction {
    type: typeof UPDATE_KNOWLEDGE;
    payload: {
        tools: Array<Tool>;
    };
}

interface UpdateUseCaseIdAction {
    type: typeof UPDATE_USE_CASE_ID;
    payload: {
        id: number;
    };
}

interface DeleteFileAction {
    type: typeof DELETE_FILE;
    payload: {
        toolIndex: number;
        fileIndex: number;
    };
}

interface EditFileAction {
    type: typeof EDIT_FILE;
    payload: {
        toolIndex: number;
        fileIndex: number;
        file: FileTool;
    };
}

type UseCaseActions =
    | UpdateGenAIAction
    | UpdateAgentAction
    | UpdateAiModelAction
    | UpdateKnowledgeAction
    | UpdateUseCaseIdAction
    | DeleteFileAction
    | EditFileAction;

type State = {
    id: number | null | undefined;
    genAi: {
        name: string;
        domain: string;
        description: string;
        maxBudget: number | null;
        isActive: boolean;
    };
    agent: {
        agentName: string;
        agentDesc: string;
        img: File | null;
    };
    aiModel: {
        provider_id: number | null;
        model_id: number | null;
        region_id: number | null;
    };
    knowledge: {
        tools: Array<Tool>;
    };
};

const initialState: State = {
    id: null,
    genAi: {
        name: '',
        domain: '',
        description: '',
        maxBudget: null,
        isActive: false,
    },
    agent: {
        agentName: '',
        agentDesc: '',
        img: null,
    },
    aiModel: {
        provider_id: null,
        model_id: null,
        region_id: null,
    },
    knowledge: {
        tools: [],
    },
};

export const UseCaseContext = createContext<{ state: State; dispatch: Dispatch<UseCaseActions> }>({
    state: initialState,
    dispatch: () => {},
});

const useCaseReducer: Reducer<State, UseCaseActions> = (state: State, action: UseCaseActions) => {
    switch (action.type) {
        case UPDATE_USE_CASE_ID:
            return {
                ...state,
                id: action.payload.id,
            };
        case UPDATE_GEN_AI:
            return {
                ...state,
                genAi: action.payload,
            };
        case UPDATE_AGENT:
            return { ...state, agent: action.payload };
        case UPDATE_AI_MODEL:
            return { ...state, aiModel: action.payload };
        case UPDATE_KNOWLEDGE:
            return { ...state, knowledge: action.payload };
        case DELETE_FILE:
            return { ...state };
        case EDIT_FILE:
            return { ...state };
        default:
            return state;
    }
};

export const UseCaseContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(useCaseReducer, initialState);

    const value = { state, dispatch };

    return <UseCaseContext.Provider value={value}>{children}</UseCaseContext.Provider>;
};
