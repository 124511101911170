/* eslint-disable react/no-children-prop */
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import useLayoutManagement from '../../Hooks/LayoutManagement/useLayoutManagement';
import { getFile } from '../../Services/http/content.service';

const useStyles = makeStyles((theme) => ({
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        width: 'calc(100% / 3 - 40px)',
        padding: '0 20px 0 20px',
    },
    circularProgressContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const HelpActionContainer: React.FC = () => {
    const classes = useStyles();
    const layoutManagement = useLayoutManagement();
    const location = useLocation();

    const currentPage = location.pathname === '/' ? 'home' : location.pathname.replace(/\//g, ''); // ...remove leading slashes
    const markdownFilename = `ap_help_${currentPage}.md`;
    const noHelpAvailableMarkdown =
        '# Oops... \n There is currently no help available for this page.';

    const toggleHelpDrawerIsActive = () => {
        layoutManagement.dispatch({
            type: 'UPDATE_HELP_DRAWER_IS_ACTIVE',
            payload: { helpDrawerIsActive: !layoutManagement.state.helpDrawerIsActive },
        });
    };

    const [markdownText, setMarkdownText] = useState('');
    useEffect(() => {
        getFile('admin_portal_help', markdownFilename)
            .then(async (res) => {
                const blob = new Blob([res.data], { type: 'text/markdown' });
                const text = await blob.text();
                setMarkdownText(text);
            })
            .catch((error) => {
                setMarkdownText(noHelpAvailableMarkdown);
            });
    }, [markdownFilename, noHelpAvailableMarkdown]);

    return (
        <>
            <IconButton
                color={layoutManagement.state.helpDrawerIsActive ? 'secondary' : 'inherit'}
                id="admin-portal-header-help"
                onClick={toggleHelpDrawerIsActive}
            >
                <QuestionMarkIcon />
            </IconButton>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={layoutManagement.state.helpDrawerIsActive}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                {markdownText ? (
                    <ReactMarkdown children={markdownText}></ReactMarkdown>
                ) : (
                    <div className={classes.circularProgressContainer}>
                        <CircularProgress />
                    </div>
                )}
            </Drawer>
        </>
    );
};

export default HelpActionContainer;
