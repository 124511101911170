import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar, VariantType } from 'notistack';

interface Props {
    onSubmit: (refreshWindow: number, redirectUrl: string) => void;
    toggleDialog: () => void;
    errorMessage: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            padding: theme.spacing(1, 3),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: theme.spacing(3),
        },
        closeButton: {
            color: theme.palette.grey[500],
            transform: 'translateX(25%)',
        },
        label: {
            whiteSpace: 'nowrap',
            marginBottom: theme.spacing(1),
        },
        progress: {
            marginRight: '20px',
        },
        inputContainer: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: 70,
            },
        },
        inputString: {
            '& .MuiTextField-root': {
                width: 200,
            },
        },
    }),
);

const RefreshEventDialog: FC<Props> = ({ onSubmit, toggleDialog, errorMessage }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(true);
    const [refreshWindow, setRefreshWindow] = useState<number>(10);
    const [isLoading, setIsLoading] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState<string>('');

    const handleClose = () => {
        setOpen(false);
        toggleDialog();
    };

    const onRefreshValueChange = (event?: any) => {
        if (event) {
            setRefreshWindow(event.target?.value);
        }
    };
    const onRedirectUrlChange = (event?: any) => {
        if (event) {
            setRedirectUrl(event.target?.value);
        }
    };

    const isValidUrl = (url: string) => {
        try {
            new URL(url);
        } catch (_) {
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (isValidUrl(redirectUrl) || !redirectUrl) {
            setIsLoading(true);
            await onSubmit(refreshWindow, redirectUrl);
            setIsLoading(false);
            setRefreshWindow(10);
        } else {
            const errorMessage = 'Url error: not valid url';
            const variant: VariantType = 'error';
            enqueueSnackbar(errorMessage, {
                variant,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        }
    };

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                disableEnforceFocus
            >
                <DialogTitle
                    disableTypography
                    className={classes.dialogTitle}
                    id="customized-dialog-title"
                >
                    <Typography>Browser Refresh</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                        id="domains-refresh-domain-dialog-close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography>
                        {
                            "Do you want to force all active users in the selected domain to refresh their browser session? This will shortly interrupt the user's experience."
                        }
                    </Typography>
                    <div className={classes.inputContainer}>
                        <Typography>{'Refresh delay window (s)'}</Typography>
                        <TextField
                            value={refreshWindow}
                            type={'number'}
                            InputProps={{ inputProps: { min: 0 } }}
                            placeholder={'Time (seconds)'}
                            onChange={onRefreshValueChange}
                        />
                    </div>
                    <div className={`${classes.inputContainer} ${classes.inputString}`}>
                        <Typography>{'Redirect url'}</Typography>
                        <TextField
                            value={redirectUrl}
                            type={'string'}
                            placeholder={'Url'}
                            onChange={onRedirectUrlChange}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    {!isLoading ? (
                        <span>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                id="domains-refresh-domain-dialog-cancel"
                            >
                                Cancel
                            </Button>

                            <Button
                                onClick={handleSubmit}
                                color="primary"
                                disabled={refreshWindow < 0}
                                id="domains-refresh-domain-dialog-confirm"
                            >
                                Confirm
                            </Button>
                        </span>
                    ) : (
                        <span>
                            <div className={classes.progress}>
                                <CircularProgress />
                            </div>
                        </span>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RefreshEventDialog;
