import {
    Button,
    Checkbox,
    createStyles,
    Dialog,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    makeStyles,
    TextField,
    Theme,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FC, useEffect } from 'react';
import useUserManagement from '../../../Hooks/UserManagement/useUserManagement';
import { UserCell, RoomCell, UserSubCell } from '../../../Models/table-cells.model';
import { SelectedUserRoles, UserRoles } from '../../../Models/userRole.model';
import { getRooms } from '../../../Services/http/content.service';
import { fetchAllEvents } from '../../../Services/http/event.service';
import useAuth from '../../../Hooks/Auth/useAuth';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: { width: '30vw', padding: '10px' },
        remove: {
            margin: '0  120px 0 40px ',
        },
        'form-div': {
            minHeight: 'calc(120% )',
            overflowY: 'auto',
        },
        'form-container': {
            margin: '20px 10px 40px 10px',
        },
        'edit-actions': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '20px 10px',
            width: '80%',
        },
        'user-names': {
            padding: '0 0 40px 40px ',
            margin: '0',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '80%',
            maxHeight: '200px',
            overflowY: 'auto',
        },
        user: { margin: '10px 10px 0 0', padding: '0', width: 'auto' },
        note: { maxWidth: '260px' },
        title: {
            minWidth: '300px',
            margin: '0px 10px',
            color: theme.palette.primary.main,
            fontWeight: 600,
            padding: '0',
        },
        subTitle: {
            margin: '0px 10px !important',
            color: `${theme.palette.primary.main} !important`,
        },
        purple: {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
        'room-tags': {
            maxWidth: '250px',
            margin: '0px 10px',
        },
        'inner-checkbox': {
            padding: '0 0 0 40px',
        },
        'multiple-checkbox-container': {
            maxHeight: '300px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
        },
        'access-blocked': {
            color: 'red',
        },
    }),
);

const createSelectedData = (selectedUser: any, subCell?: any) => {
    let roles = (subCell && JSON.parse(JSON.stringify(subCell?.roles))) || [];
    const updatedRoles: any[] = [];
    roles.forEach((role: any) => {
        if (role.role) {
            if (role.room) {
                const rooms = role.room.split(', ');
                if (rooms.length > 0) {
                    roles = [];
                    rooms.forEach((room: any) =>
                        updatedRoles.push({ role: role.role, room: room }),
                    );
                }
            } else {
                updatedRoles.push({ role: role.role });
            }
        }
    });
    const userRoleList: SelectedUserRoles = {
        id: selectedUser.id,
        event_code: subCell?.event || undefined,
        roles: updatedRoles,
    };
    return userRoleList;
};

interface Props {
    open: boolean;
    onClose: () => void;
    onConfirm: (selectedUserRoles: SelectedUserRoles) => void;
    cell: UserCell;
    subCell?: UserSubCell;
}

const UserRolesDialog: FC<Props> = ({ open, onClose, onConfirm, cell, subCell }) => {
    const classes = useStyles();
    const { state: userState, dispatch } = useUserManagement();
    const { state: authState } = useAuth();
    const [disable, setDisable] = React.useState(true);
    const [selectedUserRoles, setSelectedUserRoles] = React.useState<SelectedUserRoles>(
        createSelectedData(cell, subCell),
    );
    const { t } = useTranslation();
    const roleMapping: { [key: string]: string } = {
        admin: 'platform-admin',
        'event-admin': 'domain-admin',
    };

    const mapRole = (role: string): string => {
        return roleMapping[role] || role;
    };
    const [allEvents, setAllEvents] = React.useState([]);
    const [eventRooms, setEventRooms] = React.useState<RoomCell[]>([]);
    const handleSave = () => {
        removeEmptyRoles();
        checkRooms();
        onConfirm(selectedUserRoles);
    };

    const handleCancel = () => {
        if (!subCell) {
            selectEvent('');
        }
        onClose();
    };
    useEffect(() => {
        setSelectedUserRoles({ ...createSelectedData(cell, subCell) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cell]);

    const checkDisableCondition = (userRoles: SelectedUserRoles) => {
        if (
            userRoles.roles.length <= 0 ||
            userRoles.roles.some(
                (role) => role.role != 'platform-admin' && !userRoles.event_code,
            ) ||
            userRoles.roles.some(
                (role) =>
                    role.role !== 'domain-admin' &&
                    role.role !== 'platform-admin' &&
                    role.role !== 'visitor' &&
                    role.role !== 'access-blocked' &&
                    !role.room,
            )
        ) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    };

    useEffect(() => {
        if (open) {
            setSelectedUserRoles(createSelectedData(cell, subCell));
        }
    }, [open, cell, subCell]);

    useEffect(() => {
        if (!subCell?.event) {
            getEventNames();
        } else {
            getEventSpecificRooms(subCell.event);
        }
        setSelectedUserRoles({ ...createSelectedData(cell, subCell) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subCell?.event]);

    const getEventNames = async () => {
        if (allEvents.length == 0) {
            await fetchAllEvents()
                .then((res) => setAllEvents(res.data.events))
                .catch((error) => error);
        }
    };

    const getEventSpecificRooms = (event: string) => {
        getRooms(event)
            .then((res) => {
                setEventRooms(res.data.event_rooms[0].rooms);
            })
            .catch((error) => error);
    };

    const removeEmptyRoles = () => {
        const roles: any[] = [];
        selectedUserRoles.roles.map((role) => {
            if (role.role !== null) {
                roles.push(role);
            }
        });
        setSelectedUserRoles({
            id: selectedUserRoles.id,
            event_code: selectedUserRoles.event_code,
            roles,
        });
    };

    const updateSelectedRoles = (role: string, room?: number) => {
        if (selectedUserRoles.roles?.some((_role) => _role.role === role)) {
            const roles: any = [];
            selectedUserRoles.roles.map((_role) => {
                if (_role.role !== role) roles.push(_role);
            });
            const keptRoles = {
                id: selectedUserRoles.id,
                event_code: selectedUserRoles.event_code,
                roles,
            };
            setSelectedUserRoles(keptRoles);
            checkDisableCondition(keptRoles);
        } else {
            const newRoles = {
                id: selectedUserRoles.id,
                event_code: selectedUserRoles.event_code,
                roles: [...selectedUserRoles.roles, { role, room }],
            };
            setSelectedUserRoles(newRoles);
            checkDisableCondition(newRoles);
        }
    };

    const updateRooms = (rooms: RoomCell[], role: string) => {
        const updatedRooms = selectedUserRoles;

        updatedRooms.roles = updatedRooms?.roles?.filter((_role) => {
            return _role.role !== role;
        });
        rooms.forEach((room) =>
            updatedRooms.roles.push({
                role,
                room: room.id,
            }),
        );
        setSelectedUserRoles(updatedRooms);
        checkDisableCondition(updatedRooms);
    };

    const checkRooms = () => {
        selectedUserRoles.roles.map((role) => {
            if (role.room && !Number.isInteger(role.room) && subCell) {
                const filteredRoom = userState.rooms
                    .find((eventRoom) => eventRoom.event === subCell.event)!
                    .rooms.filter((room) => {
                        return room.name === role.room;
                    });
                role.room =
                    filteredRoom.length > 0 && filteredRoom[0].id ? filteredRoom[0].id : undefined;
            }
        });
    };

    const selectEvent = (event: any) => {
        getEventSpecificRooms(event);
        setSelectedUserRoles({ ...selectedUserRoles, event_code: event });
    };

    const renderRoomBasedCheckbox = (name: string, hasRooms: boolean) => {
        const visitorRooms = eventRooms.filter((room) => {
            return room.restricted;
        });
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={() => updateSelectedRoles(name)}
                            checked={selectedUserRoles.roles?.some((_role) => _role.role === name)}
                            name={name.charAt(0).toUpperCase() + name.slice(1)}
                            id="domain-role-assignment-role-checkbox"
                        />
                    }
                    label={name.charAt(0).toUpperCase() + name.slice(1)}
                />
                {hasRooms && selectedUserRoles.roles?.some((_role) => _role.role === name) && (
                    <>
                        {name === 'visitor' && (
                            <p className={classes.note}>
                                <strong>Please note: </strong>
                                All visitors have access to unrestricted reality linkings by
                                default. Access to restricted reality linkings must be granted
                                explicitly.
                            </p>
                        )}
                        <Autocomplete
                            className={classes['room-tags']}
                            multiple
                            id="tags-outlined"
                            options={name === 'visitor' ? visitorRooms : eventRooms}
                            defaultValue={eventRooms.filter((room) => {
                                return (
                                    selectedUserRoles.roles.filter((role) => {
                                        return (
                                            (role.room === room.name || role.room === room.id) &&
                                            role.role === name
                                        );
                                    }).length > 0
                                );
                            })}
                            onChange={(event, newValue) => {
                                updateRooms(newValue, name);
                            }}
                            getOptionLabel={(room: RoomCell) => room.name}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={
                                        name === 'visitor'
                                            ? 'Restricted reality linking access'
                                            : 'Apply for reality linking *'
                                    }
                                    placeholder={`${
                                        name.charAt(0).toUpperCase() + name.slice(1)
                                    } reality linking`}
                                />
                            )}
                        />
                    </>
                )}
            </>
        );
    };

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title" className={classes.title}>
                {t('userManagement.domainAndRole')}
            </DialogTitle>
            <DialogContentText
                className={classes.subTitle}
            >{`Username: ${cell.username}`}</DialogContentText>
            {subCell?.event ? (
                <DialogContentText
                    className={classes.subTitle}
                >{`Domain: ${subCell.event}`}</DialogContentText>
            ) : (
                <Autocomplete
                    className={classes['room-tags']}
                    id="tags-outlined"
                    options={allEvents}
                    onChange={(event, newValue) => {
                        selectEvent(newValue.event_code);
                    }}
                    getOptionLabel={(event: any) => event.event_code}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            variant="outlined"
                            label={t('userManagement.domain')}
                        />
                    )}
                />
            )}
            <FormControl component="fieldset" className={classes['form-div']}>
                {eventRooms && (
                    <FormGroup className={classes['form-container']}>
                        {renderRoomBasedCheckbox('visitor', true)}
                        {renderRoomBasedCheckbox(t('userManagement.domainAdmin'), false)}
                        {authState.currentUser.roles.some((role: any) => {
                            const mappedRole = mapRole(role.role);
                            return mappedRole === 'platform-admin';
                        }) && renderRoomBasedCheckbox(t('userManagement.platformAdmin'), false)}

                        {renderRoomBasedCheckbox('presenter', true)}
                        <div className={classes['access-blocked']}>
                            {renderRoomBasedCheckbox('access-blocked', false)}
                        </div>
                    </FormGroup>
                )}
            </FormControl>
            <div className={classes['edit-actions']}>
                <Button
                    onClick={handleCancel}
                    variant="contained"
                    id="domain-role-assignment-dialog-cancel"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    className={classes.purple}
                    disabled={disable}
                    id="domain-role-assignment-dialog-save"
                >
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default UserRolesDialog;
