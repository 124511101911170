import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { Tool } from '../../Models/useCase.model';
import { cutString } from '../../Utils/formatString';

const maxChar: number = 90;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        surface: {
            display: 'flex',
            flexDirection: 'column',
            width: '11.5625rem;',
            border: '0.5px solid #484848',
            backgroundColor: '#1E1E1E',
            borderRadius: theme.spacing(1),
        },
        header: {
            display: 'flex',
            padding: '0.5rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
            backgroundColor: '#2B2B2E',
            flexDirection: 'row',
            height: '30px',
            borderRadius: theme.spacing(1, 1, 0, 0),
        },
        content: {
            padding: theme.spacing(1, 2),
        },
        overSizeTopic: {
            borderColor: theme.palette.error.main,
        },
    }),
);

interface Props {
    tool: Tool;
    selected: boolean;
    onEdit: () => void;
    onDelete: () => void;
}

export const TopicCard: React.FC<Props> = ({ tool, onEdit, onDelete }) => {
    const classes = useStyles();

    function isOverSize(): string {
        if (tool.used_size_mb > tool.max_size_mb) {
            return classes.overSizeTopic;
        } else return '';
    }

    function convertMbToGb(mb: number): number {
        const gb = mb * 0.001;
        return Math.round(gb * 100) / 100;
    }

    return (
        <div className={`${classes.surface} ${isOverSize()}`}>
            <div className={classes.header}>
                <IconButton onClick={onDelete} color="inherit" size="small">
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
                <Typography variant="caption">{`${convertMbToGb(tool.used_size_mb)}/${convertMbToGb(
                    tool.max_size_mb,
                )}GB`}</Typography>
                <IconButton onClick={onEdit} color="inherit" size="small">
                    <ModeEditOutlineOutlinedIcon fontSize="small" />
                </IconButton>
            </div>
            <div className={classes.content}>
                <Typography variant="body1">{tool.name}</Typography>
                {tool.description && (
                    <Typography variant="caption">
                        {cutString(tool.description, maxChar)}
                    </Typography>
                )}
            </div>
        </div>
    );
};
