import React, { FC, useState } from 'react';
import ActionButton from '../../Components/ActionButton/ActionButton';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { getLocalStorageItemValue } from '../../Services/local-storage.service';
import { createEvent } from '../../Services/http/event.service';
import EventDialog from '../../Components/Dialogs/EventDialog/EventDialog';
import { Event } from '../../Models/event.model';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

interface Props {
    onCompleteCallback: () => void;
}

const CreateEventActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const currentUser: any = JSON.parse(getLocalStorageItemValue('currentUser'));

    let hasPermission = false;
    for (const role of currentUser.roles) {
        if (role.role === 'admin') {
            hasPermission = true;
            break;
        }
    }

    const onSubmit = async (event: Event) => {
        await createEvent(event)
            .then(() => {
                onCompleteCallback();
                onToggle();
                enqueueSnackbar('Event was created', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            })
            .catch((e) => {
                if (e.response?.data?.message) {
                    setErrorMessage(e.response.data.message);
                }
            });
    };

    const onToggle = () => {
        setErrorMessage('');
        setOpen(!open);
    };

    return (
        <>
            {hasPermission && (
                <>
                    <ActionButton
                        onClick={onToggle}
                        text={t('domainManagement.buttonDomain')}
                        icon={<BorderColorIcon />}
                        id="domains-create-domain"
                    />
                    {open && (
                        <EventDialog
                            onSubmit={onSubmit}
                            toggleDialog={onToggle}
                            errorMessage={errorMessage}
                            editEvent={null}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default CreateEventActionContainer;
