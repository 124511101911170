import React, { FC, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { getPosterFile } from '../../Services/http/content.service';

const userStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
        },
        posterContainer: {
            flex: 1.05,
            backgroundPosition: 'center',
            backgroundColor: 'white',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        formContainer: {
            flex: 4,
            backgroundColor: '#1E1E1E',
            overflowX: 'hidden',
            overflowY: 'auto',
            [theme.breakpoints.up('md')]: {
                flex: 0.95,
            },
        },
    }),
);

const AuthLayout: FC = ({ children }) => {
    const classes = userStyles();
    const [posterImage, setPosterImage] = useState<string>();

    useEffect(() => {
        getPosterFile().then((res) => {
            const image = URL.createObjectURL(res.data);
            setPosterImage(image);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className={classes.container}>
            <Box
                className={classes.posterContainer}
                style={{ backgroundImage: `url(${posterImage})` }}
            />
            <Box className={classes.formContainer}>{children}</Box>
        </Box>
    );
};

export default AuthLayout;
