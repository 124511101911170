import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { UPDATE_KNOWLEDGE } from '../../Contexts/useCaseContext';
import useCaseManagement from '../../Hooks/UseCaseManagement/UseCaseManagement';
import { Tool } from '../../Models/useCase.model';
import { CardCreation } from '../CardCreation/CardCreation';
import { TopicCard } from '../FolderCard/FolderCard';
import { ToolForm } from '../FolderCard/SiloBlock';
import { InputField } from '../InputField/InputField';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        knowledge: {
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(2),
            width: '500px',
            overflowX: 'scroll',
            overflowY: 'hidden',
        },
        subCard: {
            backgroundColor: '#2B2B2E',
            borderRadius: theme.spacing(1),
            borderColor: '#484848',
            border: '0.5px solid',
            padding: theme.spacing(6, 2),
            height: '100%',
            color: theme.palette.text.primary,
            cursor: 'pointer',
        },
        folderInfos: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            width: '100%',
        },
    }),
);

export const KnowledgeForm: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { state, dispatch } = useCaseManagement();

    const [selectedToolIndex, setSelectedToolIndex] = useState<number>();
    const [newSelectedTopicName, setSelectedTopicName] = useState<string>('');
    const [newSelectedTopicDescription, setSelectedTopicDescription] = useState<string>('');
    const [openTopicForm, setOpenToolForm] = useState<boolean>(false);

    const buttonStyle = {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.background.default,
        width: '120px',
    };

    useEffect(() => {
        setSelectedTopicName(
            selectedToolIndex ? state.knowledge.tools[selectedToolIndex].name : '',
        );
        setSelectedTopicDescription(
            selectedToolIndex ? state.knowledge.tools[selectedToolIndex].description : '',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedToolIndex]);

    function deleteTool(toolIndex: number, toolId: number | null) {
        if (toolId == null) {
            // deleteSilo(toolIndex, );
        }
        const tempToolsList = [...state.knowledge.tools];
        tempToolsList.splice(toolIndex, 1);
        setSelectedToolIndex(undefined);
        setOpenToolForm(false);
        updateKnowledgeState(tempToolsList);
    }

    function addTool() {
        if (newSelectedTopicName !== '') {
            const newTool: Tool = {
                id: null,
                name: newSelectedTopicName,
                description: newSelectedTopicDescription,
                type: 'knowledge',
                max_size_mb: 3072,
                used_size_mb: 0,
                files: [],
            };
            const tempToolList: Array<Tool> = [...state.knowledge.tools];
            tempToolList.push(newTool);
            updateKnowledgeState(tempToolList);
            setSelectedToolIndex(undefined);
            setOpenToolForm(false);
        }
    }

    function editTool() {
        setOpenToolForm(true);
        setSelectedToolIndex(undefined);
    }

    function modifyTool(toolIndex: number) {
        setSelectedToolIndex(toolIndex);
    }

    function updateKnowledgeState(newTools: Array<Tool>) {
        dispatch({
            type: UPDATE_KNOWLEDGE,
            payload: {
                tools: newTools,
            },
        });
    }

    return (
        <CardCreation title="Knowledge" popoverContent="upload all documents to train the model">
            <div className={classes.folderInfos}>
                {selectedToolIndex == null && openTopicForm && (
                    <div className={classes.folderInfos}>
                        <InputField
                            placeHolder="Name:"
                            value={newSelectedTopicName}
                            onChange={(v) => setSelectedTopicName(v)}
                        />
                        <InputField
                            placeHolder="description:"
                            value={newSelectedTopicDescription}
                            onChange={(v) => setSelectedTopicDescription(v)}
                        />
                        <Button
                            variant="contained"
                            sx={buttonStyle}
                            onClick={addTool}
                            disabled={newSelectedTopicName.length === 0}
                        >
                            Add Topic
                        </Button>
                        <Divider />
                    </div>
                )}
                {selectedToolIndex != null && (
                    <ToolForm
                        toolIndex={selectedToolIndex}
                        goBack={() => setSelectedToolIndex(undefined)}
                    />
                )}
                <Grid container direction="column" gap={2}>
                    <Grid item>
                        <Typography variant="body2">Root</Typography>
                    </Grid>
                    <Grid item>
                        <div className={classes.knowledge}>
                            <button type="button" onClick={editTool} className={classes.subCard}>
                                <AddIcon fontSize="large" />
                                <Typography variant="h6" fontWeight="fontWeightBold">
                                    Add New Topic
                                </Typography>
                            </button>
                            {state.knowledge.tools.length > 0 &&
                                state.knowledge.tools.map((tool, index) => (
                                    <TopicCard
                                        key={tool.name}
                                        tool={tool}
                                        onEdit={() => {
                                            modifyTool(index);
                                        }}
                                        onDelete={() => {
                                            deleteTool(index, tool.id);
                                        }}
                                        selected={index === selectedToolIndex}
                                    />
                                ))}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </CardCreation>
    );
};
