import Button from '@material-ui/core/Button';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';

interface Props {
    id?: string;
    text: string;
    icon?: ReactNode;
    disabled?: boolean;
    onClick: () => void;
}
const styles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
    }),
);

const ActionButton: React.FC<Props> = ({ id, icon, text, disabled, onClick }) => {
    const classes = styles();
    return (
        <Button
            id={id}
            key={text}
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={icon}
            disabled={disabled}
            onClick={onClick}
        >
            {text}
        </Button>
    );
};

export default ActionButton;
