import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useParams } from 'react-router-dom';
import { UseCaseForm } from '../../Components/UseCaseForm/UseCaseForm';
import { UseCaseContextProvider } from '../../Contexts/useCaseContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(4),
            padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
        },
    }),
);

export const CreateUseCasePage: React.FC = () => {
    const classes = useStyles();
    const { useCaseId } = useParams<{ useCaseId: string }>();

    function getUseCaseId(): number | undefined {
        if (useCaseId === ':useCaseId' || useCaseId === undefined) {
            return undefined;
        }
        return Number(useCaseId);
    }

    return (
        <div className={classes.container}>
            <UseCaseContextProvider>
                <UseCaseForm useCaseId={getUseCaseId()} />
            </UseCaseContextProvider>
        </div>
    );
};
